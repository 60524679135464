import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DownloadIcon from "@mui/icons-material/Download";
import MenuBar from "@/components/MenuBar";

interface AggregatedAmount {
  currentAmount: string;
  wtd: string;
  mtd: string;
  ytd: string;
}

interface AggregatedHours {
  currentAmount: string;
  wtd: string;
  mtd: string;
  ytd: string;
  currentRate?: string;
}

interface EmployeeEarning {
  earningCode: string;
  displayName: string;
  amount: AggregatedAmount;
  hours: AggregatedHours;
}

interface EmployeeTaxes {
  taxCode: string;
  currentAmount: string;
  ytd: string;
  isEmployee: boolean;
}

interface Employee {
  id: number;
  plivId: number;
  firstName: string;
  lastName: string;
  earnings: EmployeeEarning[];
  taxes: EmployeeTaxes[];
}

type Order = "asc" | "desc";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 14,
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  fontSize: 14,
  fontWeight: 600,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
}));

function RowMenu({ onDownloadPaystub }: { onDownloadPaystub: () => void }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = () => {
    onDownloadPaystub();
    handleClose();
  };

  return (
    <>
      <IconButton
        size="small"
        onClick={handleClick}
        sx={{ color: "text.primary" }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleDownload}>
          <DownloadIcon sx={{ mr: 1 }} fontSize="small" />
          Download Paystub
        </MenuItem>
      </Menu>
    </>
  );
}

const PayrollApproval: React.FC = () => {
  const { payrunId } = useParams<{ payrunId: string }>();
  const navigate = useNavigate();
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Employee>("lastName");
  const [selected, setSelected] = useState<readonly string[]>([]);

  useEffect(() => {
    fetchEmployeesForPayrun();
  }, [payrunId]);

  const fetchEmployeesForPayrun = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No auth token found");
      }

      const response = await fetch(
        `http://localhost:8000/employees/payruns/${payrunId}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch employees for payrun");
      }

      const data = await response.json();
      setEmployees(data.employees);
    } catch (error) {
      console.error("Error fetching employees for payrun:", error);
    }
  };

  const calculateGrossPay = (earnings: EmployeeEarning[]): number => {
    return earnings.reduce((total, earning) => {
      const amount = parseFloat(earning.amount.currentAmount);
      return total + (isNaN(amount) ? 0 : amount);
    }, 0);
  };

  const calculateEmployeeTaxes = (taxes: EmployeeTaxes[]): number => {
    return taxes
      .filter((tax) => tax.isEmployee)
      .reduce((total, tax) => {
        const amount = parseFloat(tax.currentAmount);
        return total + (isNaN(amount) ? 0 : amount);
      }, 0);
  };

  const calculateEmployerTaxes = (taxes: EmployeeTaxes[]): number => {
    return taxes
      .filter((tax) => !tax.isEmployee)
      .reduce((total, tax) => {
        const amount = parseFloat(tax.currentAmount);
        return total + (isNaN(amount) ? 0 : amount);
      }, 0);
  };

  const calculateNetPay = (grossPay: number, employeeTaxes: number): number => {
    return grossPay - employeeTaxes;
  };

  const handleApprovePayroll = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No auth token found");
      }

      const response = await fetch(
        `http://localhost:8000/employees/payruns/${payrunId}/state/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            state: "APPROVED",
          }),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to approve payroll");
      }

      alert("Payroll approved successfully!");
      navigate("/dashboard");
    } catch (error) {
      console.error("Error approving payroll:", error);
      alert("Failed to approve payroll. Please try again.");
    }
  };

  const handleDownloadPaystub = (plivId: number) => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      console.error("No auth token found");
      return;
    }

    fetch(`http://localhost:8000/employees/payruns/${plivId}/paystub/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `paystub_${plivId}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading paystub:", error));
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <MenuBar />
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h5" sx={{ mb: 4 }}>
          Payrun Overview
        </Typography>
        <StyledPaper elevation={2}>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableHeaderCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 &&
                        selected.length !== employees.length
                      }
                      checked={selected.length === employees.length}
                      onChange={(event) => {
                        setSelected(
                          event.target.checked
                            ? employees.map((e) => e.id.toString())
                            : [],
                        );
                      }}
                    />
                  </StyledTableHeaderCell>
                  <StyledTableHeaderCell>Name</StyledTableHeaderCell>
                  <StyledTableHeaderCell align="right">
                    Gross Pay
                  </StyledTableHeaderCell>
                  <StyledTableHeaderCell align="right">
                    Employee Taxes
                  </StyledTableHeaderCell>
                  <StyledTableHeaderCell align="right">
                    Employer Taxes
                  </StyledTableHeaderCell>
                  <StyledTableHeaderCell align="right">
                    Net Pay
                  </StyledTableHeaderCell>
                  <StyledTableHeaderCell align="right">
                    Actions
                  </StyledTableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employees.map((employee) => {
                  const grossPay = calculateGrossPay(employee.earnings);
                  const employeeTaxes = calculateEmployeeTaxes(employee.taxes);
                  const employerTaxes = calculateEmployerTaxes(employee.taxes);
                  const netPay = calculateNetPay(grossPay, employeeTaxes);

                  return (
                    <TableRow
                      key={employee.id}
                      hover
                      selected={selected.includes(employee.id.toString())}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={selected.includes(employee.id.toString())}
                          onChange={(event) => {
                            setSelected((ids) =>
                              event.target.checked
                                ? ids.concat(employee.id.toString())
                                : ids.filter(
                                    (itemId) =>
                                      itemId !== employee.id.toString(),
                                  ),
                            );
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        {`${employee.firstName} ${employee.lastName}`}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        £{grossPay.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        £{employeeTaxes.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        £{employerTaxes.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        £{netPay.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <RowMenu
                          onDownloadPaystub={() =>
                            handleDownloadPaystub(employee.plivId)
                          }
                        />
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledPaper>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApprovePayroll}
          >
            Approve Payroll
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PayrollApproval;
