import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Drawer,
  Chip,
  CircularProgress,
  Button,
} from "@mui/material";
import { format } from "date-fns";
import AddIcon from "@mui/icons-material/Add";
import MenuBar from "@/components/MenuBar";
import CreateEnrollmentDialog from "./PensionsEnrollmentDialog";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { X } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import PensionSelectionForm from "@/components/pensions/PensionSelectionForm";

interface EnrollmentEvent {
  id: number;
  employeeId: number;
  eventType: string;
  effectiveStartDate: string;
  effectiveEndDate: string;
  isAutoRenew: boolean;
  createdAt: string;
  updatedAt: string;
  currentState: string;
  stateHistory: Array<{
    id: number;
    enrollmentEventId: number;
    state: string;
    changeEventId: number;
    createdAt: string;
  }>;
}

interface Employee {
  employeeId: number;
  firstName: string;
  lastName: string;
  eligibility: {
    employeeId: number;
    isPensionEligible: boolean;
    isAutoEnrollmentRequired: boolean;
    calculationDate: string;
    autoEnrollmentDueDate: string | null;
    pensionEligibilityDate: string;
    waitingPeriodEndDate: string | null;
  };
  activeEnrollment: EnrollmentEvent | null;
  hasActiveEnrollment: boolean;
}

const PensionsOverview: React.FC = () => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(
    null,
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [selectedEmployeeForCreate, setSelectedEmployeeForCreate] = useState<{
    id: number;
    name: string;
    startDate?: Date | null;
    hasActiveEnrollment: boolean;
  } | null>(null);

  useEffect(() => {
    fetchEmployees();
  }, []);

  function DrawerContent({
    employee,
    onClose,
  }: {
    employee: Employee;
    onClose: () => void;
  }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [enrollmentDetails, setEnrollmentDetails] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      fetchEnrollmentDetails();
    }, [employee.employeeId]);

    const fetchEnrollmentDetails = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) throw new Error("No auth token found");

        const response = await fetch(
          `http://localhost:8000/employees/benefits/${employee.employeeId}/detail/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (!response.ok) throw new Error("Failed to fetch enrollment details");

        const data = await response.json();
        setEnrollmentDetails(data.enrollmentDetails);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "Failed to fetch details",
        );
      } finally {
        setIsLoading(false);
      }
    };

    const handleSubmitSelection = async (formData: any) => {
      setIsSubmitting(true);
      setError(null);

      try {
        const token = localStorage.getItem("authToken");
        if (!token) throw new Error("No auth token found");

        const response = await fetch(
          `http://localhost:8000/employees/benefits/enrollment/${enrollmentDetails.event.id}/selections/`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              selections: [
                {
                  benefitLineId: parseInt(formData.benefitLineId),
                  employerContributionPercent:
                    formData.employerContributionPercent,
                  employeeContributionPercent:
                    formData.employeeContributionPercent,
                  isReliefAtSource: formData.isReliefAtSource,
                },
              ],
            }),
          },
        );

        if (!response.ok) throw new Error("Failed to submit selections");

        // Refresh the details
        await fetchEnrollmentDetails();
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "Failed to submit selections",
        );
      } finally {
        setIsSubmitting(false);
      }
    };

    if (isLoading) {
      return (
        <Box p={3} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Box p={3}>
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        </Box>
      );
    }

    const canMakeSelection =
      enrollmentDetails?.event?.currentState === "PENDING";

    return (
      <Box p={3} className="space-y-6">
        <div className="flex justify-between items-center">
          <Typography variant="h5">
            {`${employee.firstName} ${employee.lastName}`}
          </Typography>
          <Button onClick={onClose}>
            <X className="h-4 w-4" />
          </Button>
        </div>

        <div className="space-y-4">
          <Typography variant="h6">Eligibility Details</Typography>
          <Card>
            <CardContent className="space-y-2">
              <div className="flex justify-between py-1">
                <span className="text-muted-foreground">Status</span>
                <span className="font-medium">
                  {enrollmentDetails.eligibility.isPensionEligible
                    ? "Eligible"
                    : "Not Eligible"}
                </span>
              </div>
              <div className="flex justify-between py-1">
                <span className="text-muted-foreground">Auto Enrollment</span>
                <span className="font-medium">
                  {enrollmentDetails.eligibility.isAutoEnrollmentRequired
                    ? "Required"
                    : "Not Required"}
                </span>
              </div>
              <div className="flex justify-between py-1">
                <span className="text-muted-foreground">Eligibility Date</span>
                <span className="font-medium">
                  {format(
                    new Date(
                      enrollmentDetails.eligibility.pensionEligibilityDate,
                    ),
                    "PP",
                  )}
                </span>
              </div>
            </CardContent>
          </Card>

          <Typography variant="h6">Enrollment Details</Typography>
          <Card>
            <CardContent className="space-y-2">
              <div className="flex justify-between py-1">
                <span className="text-muted-foreground">Status</span>
                <Chip
                  className="ml-2"
                  label={enrollmentDetails.event.currentState}
                  color={
                    enrollmentDetails.event.currentState === "PENDING"
                      ? "warning"
                      : "success"
                  }
                />
              </div>
              <div className="flex justify-between py-1">
                <span className="text-muted-foreground">Start Date</span>
                <span className="font-medium">
                  {format(
                    new Date(enrollmentDetails.event.effectiveStartDate),
                    "PP",
                  )}
                </span>
              </div>
              <div className="flex justify-between py-1">
                <span className="text-muted-foreground">End Date</span>
                <span className="font-medium">
                  {format(
                    new Date(enrollmentDetails.event.effectiveEndDate),
                    "PP",
                  )}
                </span>
              </div>
              {enrollmentDetails.event.currentCoveragePeriod && (
                <div className="flex justify-between py-1">
                  <span className="text-muted-foreground">Coverage Period</span>
                  <span className="font-medium">
                    {format(
                      new Date(
                        enrollmentDetails.event.currentCoveragePeriod.startDate,
                      ),
                      "PP",
                    )}
                    {enrollmentDetails.event.currentCoveragePeriod.endDate &&
                      ` - ${format(new Date(enrollmentDetails.event.currentCoveragePeriod.endDate), "PP")}`}
                  </span>
                </div>
              )}
            </CardContent>
          </Card>

          {enrollmentDetails.currentSelections?.length > 0 && (
            <>
              <Typography variant="h6">Current Selections</Typography>
              <Card>
                <CardContent className="space-y-2">
                  {enrollmentDetails.currentSelections.map((selection: any) => {
                    // Find the corresponding benefit line from available options
                    const benefitLine = enrollmentDetails.availableOptions.find(
                      (option: any) => option.id === selection.benefitLineId,
                    );

                    return (
                      <div key={selection.id} className="space-y-2">
                        <div className="flex justify-between py-1">
                          <span className="text-muted-foreground">Scheme</span>
                          <span className="font-medium">
                            {benefitLine?.displayName ||
                              `Scheme ${selection.benefitLineId}`}
                          </span>
                        </div>
                        <div className="flex justify-between py-1">
                          <span className="text-muted-foreground">
                            Employer Contribution
                          </span>
                          <span className="font-medium">
                            {selection.employerContributionPercent}%
                          </span>
                        </div>
                        <div className="flex justify-between py-1">
                          <span className="text-muted-foreground">
                            Employee Contribution
                          </span>
                          <span className="font-medium">
                            {selection.employeeContributionPercent}%
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </CardContent>
              </Card>
            </>
          )}

          {canMakeSelection && !enrollmentDetails.currentSelections?.length && (
            <>
              <Typography variant="h6">Make Selection</Typography>
              <PensionSelectionForm
                eventId={enrollmentDetails.event.id}
                availableOptions={enrollmentDetails?.availableOptions || []}
                onSubmit={handleSubmitSelection}
                isSubmitting={isSubmitting}
                error={error}
              />
            </>
          )}
        </div>
      </Box>
    );
  }

  const fetchEmployees = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) throw new Error("No auth token found");

      const response = await fetch(
        "http://localhost:8000/employees/benefits/overview/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) throw new Error("Failed to fetch employees");

      const data = await response.json();
      setEmployees(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const createEnrollment = async (
    employeeId: number,
    effectiveDate: Date,
    coverageStartDate: Date,
  ) => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) throw new Error("No auth token found");

      const response = await fetch(
        `http://localhost:8000/employees/benefits/enrollment/create/${employeeId}/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            effective_date: effectiveDate.toISOString(),
            coverage_start_date: coverageStartDate.toISOString(),
          }),
        },
      );

      if (!response.ok) throw new Error("Failed to create enrollment");

      await fetchEmployees();
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "Failed to create enrollment",
      );
      throw err;
    }
  };

  const handleCreateClick = (
    employeeId: number,
    name: string,
    startDate: string | null,
    hasActiveEnrollment: boolean,
    event: React.MouseEvent,
  ) => {
    event.stopPropagation();
    setSelectedEmployeeForCreate({
      id: employeeId,
      name,
      startDate: startDate ? new Date(startDate) : null,
      hasActiveEnrollment,
    });
    setCreateDialogOpen(true);
  };

  const getChipColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "pending":
        return "warning";
      case "completed":
        return "success";
      case "executed":
        return "info";
      default:
        return "default";
    }
  };

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), "PP");
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <MenuBar />
      <Box className="flex-1 overflow-auto p-8">
        <Typography variant="h4" component="h1" gutterBottom>
          Pensions Overview
        </Typography>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Employee Name</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>Pension Eligible</TableCell>
                <TableCell>Auto Enrollment</TableCell>
                <TableCell>Current Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((employee) => (
                <TableRow
                  key={employee.employeeId}
                  hover
                  onClick={() => {
                    if (employee.hasActiveEnrollment) {
                      setSelectedEmployee(employee);
                      setIsDrawerOpen(true);
                    }
                  }}
                  style={{
                    cursor: employee.hasActiveEnrollment
                      ? "pointer"
                      : "default",
                  }}
                >
                  <TableCell>
                    {`${employee.firstName} ${employee.lastName}`}
                  </TableCell>
                  <TableCell>
                    {employee.eligibility.pensionEligibilityDate
                      ? format(
                          new Date(employee.eligibility.pensionEligibilityDate),
                          "PP",
                        )
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={
                        employee.eligibility.isPensionEligible
                          ? "Eligible"
                          : "Not Eligible"
                      }
                      color={
                        employee.eligibility.isPensionEligible
                          ? "success"
                          : "error"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={
                        employee.eligibility.isAutoEnrollmentRequired
                          ? "Required"
                          : "Not Required"
                      }
                      color={
                        employee.eligibility.isAutoEnrollmentRequired
                          ? "primary"
                          : "default"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {employee.hasActiveEnrollment ? (
                      <Chip
                        label={employee.activeEnrollment!.currentState}
                        color={getChipColor(
                          employee.activeEnrollment!.currentState,
                        )}
                      />
                    ) : (
                      <Chip label="No Active Enrollment" />
                    )}
                  </TableCell>
                  <TableCell>
                    {!employee.hasActiveEnrollment &&
                      employee.eligibility.isPensionEligible && (
                        <Button
                          startIcon={<AddIcon />}
                          variant="contained"
                          size="small"
                          onClick={(e) =>
                            handleCreateClick(
                              employee.employeeId,
                              `${employee.firstName} ${employee.lastName}`,
                              employee.eligibility.pensionEligibilityDate,
                              employee.hasActiveEnrollment,
                              e,
                            )
                          }
                        >
                          Create Enrollment
                        </Button>
                      )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          PaperProps={{
            sx: { width: "40%" },
          }}
        >
          {selectedEmployee && (
            <DrawerContent
              employee={selectedEmployee}
              onClose={() => setIsDrawerOpen(false)}
            />
          )}
        </Drawer>

        {selectedEmployeeForCreate && (
          <CreateEnrollmentDialog
            open={createDialogOpen}
            onClose={() => {
              setCreateDialogOpen(false);
              setSelectedEmployeeForCreate(null);
            }}
            employeeId={selectedEmployeeForCreate.id}
            employeeName={selectedEmployeeForCreate.name}
            employeeStartDate={selectedEmployeeForCreate.startDate}
            hasExistingEvent={selectedEmployeeForCreate.hasActiveEnrollment}
            onSubmit={createEnrollment}
          />
        )}
      </Box>
    </div>
  );
};

export default PensionsOverview;
