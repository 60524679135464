import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useNavigate } from "react-router-dom";
import MenuBar from "@/components/MenuBar";
import { ChevronRight } from "lucide-react";

interface Employee {
  id: number;
  firstName: string;
  lastName: string;
  homeAddress: {
    street_number: string;
    route: string;
    locality: string;
    state: string;
    postal_code: string;
    country: string;
  };
  phoneNumber: string | null;
  gender: "M" | "F" | "O" | null;
  dob: string | null;
}

interface Employees {
  employees: Employee[];
}

const phoneRegex = /^\+?[1-9]\d{1,14}$/;

const formSchema = z.object({
  firstName: z
    .string()
    .min(1, "First name is required")
    .max(35, "First name must be 35 characters or less")
    .regex(/^[A-Za-z]+$/, "First name must only contain alphabetic characters"),
  lastName: z
    .string()
    .min(1, "Last name is required")
    .max(35, "Last name must be 35 characters or less")
    .regex(/^[A-Za-z]+$/, "Last name must only contain alphabetic characters"),
  homeAddress: z.object({
    street_number: z
      .string()
      .min(1, "Street number is required")
      .regex(/^[0-9A-Za-z]+$/, "Invalid street number format"),
    route: z.string().min(1, "Street name is required"),
    locality: z.string().min(1, "Town/City is required"),
    state: z.string().optional(),
    postal_code: z
      .string()
      .regex(
        /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/,
        "Invalid postcode format",
      ),
    country: z.string().default("United Kingdom"),
  }),
  phoneNumber: z
    .string()
    .regex(phoneRegex, "Invalid phone number")
    .optional()
    .or(z.literal("")),
  gender: z.enum(["M", "F", "O"]),
  dob: z.string().refine((date) => {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age >= 16;
  }, "Employee must be at least 16 years old"),
});

type FormValues = z.infer<typeof formSchema>;

const EmployeeManagement: React.FC = () => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      homeAddress: {
        street_number: "",
        route: "",
        locality: "",
        state: "",
        postal_code: "",
        country: "United Kingdom",
      },
      phoneNumber: "",
      gender: "M",
      dob: "",
    },
  });

  const { formState } = form;

  useEffect(() => {
    fetchEmployees();
  }, []);

  const getAuthToken = () => {
    return localStorage.getItem("authToken");
  };

  const fetchEmployees = async () => {
    try {
      const token = getAuthToken();
      if (!token) {
        throw new Error("No auth token found");
      }

      const response = await fetch(
        `http://localhost:8000/employees/get_employee_personal_information/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error("Unauthorized: Please log in again");
        }
        throw new Error("Failed to fetch employees");
      }

      const data: Employees = await response.json();
      setEmployees(data.employees);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  const onSubmit = async (data: FormValues) => {
    try {
      const token = getAuthToken();
      if (!token) {
        throw new Error("No auth token found");
      }

      const response = await fetch(
        "http://localhost:8000/employees/create_employee_personal_information/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        },
      );

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error("Unauthorized: Please log in again");
        }
        throw new Error("Failed to create employee");
      }

      fetchEmployees();
      form.reset();
      setIsDialogOpen(false);
    } catch (error) {
      console.error("Error creating employee:", error);
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <MenuBar />
      <div className="flex-1 overflow-auto">
        <div className="p-8">
          <div className="flex items-center justify-between mb-8">
            <h1 className="text-3xl font-semibold">Employee Management</h1>
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <DialogTrigger asChild>
                <Button onClick={() => setIsDialogOpen(true)}>
                  Hire Employee
                </Button>
              </DialogTrigger>
              <DialogContent className="max-w-4xl">
                <DialogHeader>
                  <DialogTitle>Create New Employee</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-4 mt-4"
                  >
                    <div className="grid gridcols2 gap4">
                      <div className="spacey4">
                        <FormField
                          control={form.control}
                          name="firstName"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>First Name</FormLabel>
                              <FormControl>
                                <Input {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="lastName"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Last Name</FormLabel>
                              <FormControl>
                                <Input {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="phoneNumber"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Phone Number</FormLabel>
                              <FormControl>
                                <Input {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="gender"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Gender</FormLabel>
                              <Select
                                onValueChange={field.onChange}
                                defaultValue={field.value}
                              >
                                <FormControl>
                                  <SelectTrigger>
                                    <SelectValue placeholder="Select gender" />
                                  </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                  <SelectItem value="M">Male</SelectItem>
                                  <SelectItem value="F">Female</SelectItem>
                                  <SelectItem value="O">Other</SelectItem>
                                </SelectContent>
                              </Select>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="dob"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Date of Birth</FormLabel>
                              <FormControl>
                                <Input type="date" {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                      <div className="spacey4">
                        <FormField
                          control={form.control}
                          name="homeAddress.street_number"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Street Number</FormLabel>
                              <FormControl>
                                <Input {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="homeAddress.route"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Street Name</FormLabel>
                              <FormControl>
                                <Input {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="homeAddress.locality"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Town/City</FormLabel>
                              <FormControl>
                                <Input {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="homeAddress.state"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>County</FormLabel>
                              <FormControl>
                                <Input {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="homeAddress.postal_code"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Postcode</FormLabel>
                              <FormControl>
                                <Input {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="homeAddress.country"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Country</FormLabel>
                              <FormControl>
                                <Input {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    </div>
                    <Button type="submit" disabled={!formState.isValid}>
                      Create Employee
                    </Button>
                  </form>
                </Form>
              </DialogContent>
            </Dialog>
          </div>
          <Card>
            <CardHeader>
              <div className="flex justify-between items-center">
                <h2 className="text-2xl font-semibold">Employee List</h2>
                <Input placeholder="Search employees..." className="max-w-sm" />
              </div>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>First Name</TableHead>
                    <TableHead>Last Name</TableHead>
                    <TableHead>Home Address</TableHead>
                    <TableHead>Phone Number</TableHead>
                    <TableHead>Gender</TableHead>
                    <TableHead>Date of Birth</TableHead>
                    <TableHead></TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {employees.map((employee) => (
                    <TableRow
                      key={employee.id}
                      onClick={() => navigate(`/employee/${employee.id}`)}
                      className="cursor-pointer hover:bg-gray-100"
                    >
                      <TableCell>{employee.firstName}</TableCell>
                      <TableCell>{employee.lastName}</TableCell>
                      <TableCell>
                        {`${employee.homeAddress.street_number} ${employee.homeAddress.route}, ${employee.homeAddress.locality}, ${employee.homeAddress.postal_code}`}
                      </TableCell>
                      <TableCell>{employee.phoneNumber}</TableCell>
                      <TableCell>{employee.gender}</TableCell>
                      <TableCell>{employee.dob}</TableCell>
                      <TableCell>
                        <ChevronRight className="w-5 h-5 text-gray-400" />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EmployeeManagement;
