import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Card, CardContent } from "@/components/ui/card";

interface BenefitOption {
  id: number;
  displayName: string;
  scheme: string;
  minimumEmployerContribution: string;
  minimumEmployeeContribution: string;
  internalCode: string;
  isActive: boolean;
  countryCode: string;
}

const selectionSchema = z.object({
  benefitLineId: z.string(),
  employerContributionPercent: z
    .number()
    .min(0.03, "Employer contribution must be greater than 2%")
    .max(100, "Employer contribution cannot exceed 100%"),
  employeeContributionPercent: z
    .number()
    .min(0.05, "Employee contribution must be greater than 4%")
    .max(100, "Employee contribution cannot exceed 100%"),
  isReliefAtSource: z.boolean().default(true),
});

interface PensionSelectionFormProps {
  eventId: number;
  availableOptions: BenefitOption[];
  onSubmit: (data: z.infer<typeof selectionSchema>) => Promise<void>;
  isSubmitting: boolean;
  error?: string | null;
}

export default function PensionSelectionForm({
  eventId,
  availableOptions,
  onSubmit,
  isSubmitting,
  error,
}: PensionSelectionFormProps) {
  const form = useForm<z.infer<typeof selectionSchema>>({
    resolver: zodResolver(selectionSchema),
    defaultValues: {
      employerContributionPercent: 3,
      employeeContributionPercent: 5,
      isReliefAtSource: true,
    },
  });

  const selectedBenefitLine = form.watch("benefitLineId");
  const selectedOption = availableOptions.find(
    (opt) => opt.id.toString() === selectedBenefitLine,
  );

  // Filter out inactive options and handle duplicates by internal code
  const activeUniqueOptions = availableOptions
    .filter((option) => option.isActive)
    .reduce((acc, current) => {
      const x = acc.find((item) => item.internalCode === current.internalCode);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, [] as BenefitOption[]);

  return (
    <Card>
      <CardContent className="pt-6">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="benefitLineId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Pension Scheme</FormLabel>
                  <div className="relative">
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a pension scheme" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent
                        position="popper"
                        className="z-[9999]"
                        sideOffset={4}
                        side="bottom"
                        align="start"
                      >
                        {activeUniqueOptions.map((option) => (
                          <SelectItem
                            key={option.id}
                            value={option.id.toString()}
                          >
                            {option.displayName} ({option.scheme})
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <FormDescription>
                    Choose the pension scheme for enrollment
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            {selectedOption && (
              <>
                <FormField
                  control={form.control}
                  name="employerContributionPercent"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Employer Contribution (%)</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          step="0.01"
                          {...field}
                          onChange={(e) =>
                            field.onChange(parseFloat(e.target.value))
                          }
                        />
                      </FormControl>
                      <FormDescription>
                        Minimum required:{" "}
                        {parseFloat(selectedOption.minimumEmployerContribution)}
                        %
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="employeeContributionPercent"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Employee Contribution (%)</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          step="0.01"
                          {...field}
                          onChange={(e) =>
                            field.onChange(parseFloat(e.target.value))
                          }
                        />
                      </FormControl>
                      <FormDescription>
                        Minimum required:{" "}
                        {parseFloat(selectedOption.minimumEmployeeContribution)}
                        %
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="isReliefAtSource"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                      <div className="space-y-0.5">
                        <FormLabel className="text-base">
                          Relief at Source
                        </FormLabel>
                        <FormDescription>
                          Enable tax relief at source for employee contributions
                        </FormDescription>
                      </div>
                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </>
            )}

            {error && (
              <Alert variant="destructive">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            <Button
              type="submit"
              className="w-full"
              disabled={isSubmitting || !form.formState.isValid}
            >
              {isSubmitting ? "Submitting..." : "Submit Selection"}
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
