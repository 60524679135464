import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    // mode: "dark",
    // primary: {
    //   main: "#3399FF",
    // },
    // background: {
    //   default: "#0A1929",
    //   paper: "#132F4C",
    // },
    // text: {
    //   primary: "#B2BAC2",
    //   secondary: "#B2BAC2",
    // },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;
