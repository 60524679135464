// config/api.ts

interface ApiConfig {
  baseURL: string;
  endpoints: {
    [key: string]: string;
  };
}

interface EnvironmentConfig {
  development: ApiConfig;
  production: ApiConfig;
}

const config: EnvironmentConfig = {
  development: {
    baseURL: "http://localhost:8000",
    endpoints: {
      login: "/users/api/token/",
      signup: "/users/rest-auth/registration/",
      employeesOverview: "/employees/benefits/overview/",
      fetchEmployeeDeductions: "/employees/deductions/",
      fetchEmployeeEarnings: "/employees/get_ee_earnings/",
      fetchAvailableRateTypes: "/employees/get_available_rate_types/",
      ptoPolicy: "/employees/pto/policies/:id/",
      ptoPolicyUpdate: "/employees/pto/policies/update/:id/",
      removeCustomRate: "/employees/remove_custom_rate/",
      addCustomRate: "/employees/add_custom_rate/",
      timeOff: "/employees/pto/policies/",
      fetchEmployerFilings: "/employees/filings/employer/",
      fetchEmployerFilingDocument: "/employees/filings/filing_document/:id/",
      fetchAllUsers: "/employees/superuser/all_users/",
      fetchImports: "/employees/imports/:id/",
      createP60Import: "/employees/imports/p60/:id/",
      updateP60Import: "/employees/imports/p60/revise/:employeeId/:importId/",
      // Add other endpoints here
    },
  },
  production: {
    baseURL: "https://api.nannypaypro.com",
    endpoints: {
      login: "/users/api/token/",
      signup: "/users/rest-auth/registration/",
      ptoPolicy: "/employees/pto/policies/:id",
      fetchEmployeeDeductions: "/employees/deductions/",
      fetchEmployeeEarnings: "/employees/get_ee_earnings/",
      fetchAvailableRateTypes: "/employees/get_available_rate_types/",
      ptoPolicyUpdate: "/employees/pto/policies/update/:id/",
      employeesOverview: "/employees/benefits/overview/",
      removeCustomRate: "/employees/remove_custom_rate/",
      addCustomRate: "/employees/add_custom_rate/",
      timeOff: "/employees/pto/policies/",
      fetchEmployerFilings: "/employees/filings/employer/",
      fetchEmployerFilingDocument: "/employees/filings/filing_document/:id/",
      fetchAllUsers: "/employees/superuser/all_users/",
      fetchImports: "/employees/imports/:id/",
      createP60Import: "/employees/imports/p60/:id/",
      updateP60Import: "/employees/imports/p60/revise/:employeeId/:importId/",
      // Add other endpoints here - should match development keys
    },
  },
};

export const getApiConfig = (): ApiConfig => {
  // You can also use other environment variables here
  const environment = process.env.REACT_APP_ENV || "development";
  return config[environment as keyof EnvironmentConfig];
};

// Utility function to get full endpoint URL
export const getEndpointUrl = (
  endpointKey: string,
  params?: Record<string, string | number>,
): string => {
  const { baseURL, endpoints } = getApiConfig();
  let endpoint = endpoints[endpointKey];

  if (!endpoint) {
    throw new Error(`Endpoint ${endpointKey} not found in configuration`);
  }

  // Replace URL parameters
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      endpoint = endpoint.replace(`:${key}`, value.toString());
    });
  }

  return `${baseURL}${endpoint}`;
};

// Type-safe way to reference endpoints
export const EndpointKeys = {
  LOGIN: "login",
  SIGNUP: "signup",
  PTO_POLICY: "ptoPolicy",
  UPDATE_PTO_POLICY: "updatePtoPolicy",
  FETCH_EMPLOYEE_DEDUCTIONS: "fetchEmployeeDeductions",
  FETCH_EMPLOYEE_EARNINGS: "fetchEmployeeEarnings",
  FETCH_AVAILABLE_RATE_TYPES: "fetchAvailableRateTypes",
  FETCH_EMPLOYER_FILINGS: "fetchEmployerFilings",
  FETCH_ALL_USERS: "fetchAllUsers",
  FETCH_EMPLOYER_FILING_DOCUMENT: "fetchEmployerFilingDocument",
  REMOVE_CUSTOM_RATE: "removeCustomRate",
  ADD_CUSTOM_RATE: "addCustomRate",
  EMPLOYEES_OVERVIEW: "employeesOverview",
  TIME_OFF: "timeOff",
  FETCH_IMPORTS: "fetchImports",
  CREATE_P60_IMPORTS: "createP60Import",
  UPDATE_P60_IMPORTS: "updateP60Import",
  // Add other endpoint keys here
} as const;

export type EndpointKey = (typeof EndpointKeys)[keyof typeof EndpointKeys];
