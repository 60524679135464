// services/api.ts

import { getEndpointUrl, EndpointKey } from "../config/api";

interface ApiOptions {
  method?: string;
  headers?: Record<string, string>;
  body?: any;
  urlParams?: Record<string, string | number>;
  queryParams?: Record<string, string | number>;
  rawResponse?: boolean;
}

class ApiService {
  private static getHeaders(
    includeAuth: boolean = true,
    contentType: string = "application/json",
  ): Headers {
    const headers = new Headers({
      "Content-Type": contentType,
    });

    if (includeAuth) {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No auth token found");
      }
      headers.append("Authorization", `Bearer ${token}`);
    }

    return headers;
  }

  private static appendQueryParams(
    url: string,
    queryParams?: Record<string, string | number>,
  ): string {
    if (!queryParams) return url;

    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        searchParams.append(key, value.toString());
      }
    });

    const queryString = searchParams.toString();
    return queryString
      ? `${url}${url.includes("?") ? "&" : "?"}${queryString}`
      : url;
  }

  static async request<T>(
    endpointKey: EndpointKey,
    options: ApiOptions = {},
    includeAuth: boolean = true,
  ): Promise<T> {
    const {
      method = "GET",
      body,
      urlParams,
      queryParams,
      rawResponse = false,
    } = options;
    let url = getEndpointUrl(endpointKey, urlParams);
    url = this.appendQueryParams(url, queryParams);

    const response = await fetch(url, {
      method,
      headers: this.getHeaders(includeAuth),
      body: body ? JSON.stringify(body) : undefined,
    });

    if (!response.ok) {
      throw new Error(`API request failed: ${response.statusText}`);
    }

    if (response.status === 204) {
      return Promise.resolve() as T;
    }

    return rawResponse ? (response as unknown as T) : response.json();
  }

  static async downloadFile(
    endpointKey: EndpointKey,
    options: ApiOptions = {},
  ): Promise<Blob> {
    const { method = "GET", urlParams, queryParams } = options;
    let url = getEndpointUrl(endpointKey, urlParams);
    url = this.appendQueryParams(url, queryParams);

    const response = await fetch(url, {
      method,
      headers: this.getHeaders(true, ""), // Don't set content-type for file downloads
    });

    if (!response.ok) {
      throw new Error(`File download failed: ${response.statusText}`);
    }

    return response.blob();
  }
}

export default ApiService;
