import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardHeader, CardContent } from "./ui/card";
import { Button } from "./ui/button";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "./ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { MoreHorizontal } from "lucide-react";
import MenuBar from "./MenuBar";
import ModalEditableCell from "./ModalEditableCell";
import { Checkbox } from "@mui/material";

interface AggregatedAmount {
  currentAmount: string;
  wtd: string;
  mtd: string;
  ytd: string;
}

interface AggregatedHours {
  currentAmount: string;
  wtd: string;
  mtd: string;
  ytd: string;
  currentRate?: string;
}

interface EmployeeEarning {
  earningCode: string;
  displayName: string;
  amount: AggregatedAmount;
  hours: AggregatedHours;
}

interface EmployeeDeduction {
  deductionCode: string;
  displayName: string;
  employeeAmount: string;
  employerAmount: string;
  employeePercentage: string;
  employerPercentage: string;
  isEEAmountEditable: boolean;
  isERAmountEditable: boolean;
  isEEPercentEditable: boolean;
  isERPercentEditable: boolean;
}

interface Employee {
  id: number;
  plivId: number;
  firstName: string;
  lastName: string;
  earnings: EmployeeEarning[];
  deductions: EmployeeDeduction[];
}

interface TaxTreatment {
  taxCode: string;
  isTaxable: boolean;
  taxFreeThreshold?: number;
}

interface Earning {
  countryCode: string;
  displayName: string;
  internalEarningCode: string;
  taxTreatment: TaxTreatment;
  customRateId?: number;
}

interface Deduction {
  countryCode: string;
  displayName: string;
  internalDeductionCode: string;
  taxTreatments: any;
  employeeDeduction?: EmployeeDeduction;
}

type EarningOverrideType = "amount" | "hours" | "rate";
type DeductionOverrideType =
  | "employeeAmount"
  | "employerAmount"
  | "employeePercentage"
  | "employerPercentage";

const RunPayroll = () => {
  const { payrunId } = useParams<{ payrunId: string }>();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("earnings");
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [earnings, setEarnings] = useState<Earning[]>([]);
  const [deductions, setDeductions] = useState<Deduction[]>([]);
  const [selected, setSelected] = useState<readonly string[]>([]);

  useEffect(() => {
    fetchEmployeesForPayrun();
    fetchEarningsForPayrun();
    fetchDeductionsForPayrun();
  }, [payrunId]);

  const fetchEarningsForPayrun = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) throw new Error("No auth token found");

      const response = await fetch(
        `http://localhost:8000/employees/earnings/${payrunId}/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (!response.ok) throw new Error("Failed to fetch earnings");
      const data = await response.json();
      setEarnings(data.earnings);
    } catch (error) {
      console.error("Error fetching earnings:", error);
    }
  };

  const fetchDeductionsForPayrun = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) throw new Error("No auth token found");

      const response = await fetch(
        `http://localhost:8000/employees/deductions/${payrunId}/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (!response.ok) throw new Error("Failed to fetch deductions");
      const data = await response.json();
      setDeductions(data.deductions);
    } catch (error) {
      console.error("Error fetching deductions:", error);
    }
  };

  const fetchEmployeesForPayrun = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) throw new Error("No auth token found");

      const response = await fetch(
        `http://localhost:8000/employees/payruns/${payrunId}/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (!response.ok) throw new Error("Failed to fetch employees");
      const data = await response.json();
      setEmployees(data.employees);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  const handleEarningOverride = async (
    employee: Employee,
    earningCode: string,
    value: string,
    earningRateType: EarningOverrideType,
  ) => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) throw new Error("No auth token found");

      const response = await fetch(
        `http://localhost:8000/employees/create_earning_override/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            payrunLineItemVersion: employee.plivId,
            earningCode,
            [earningRateType]: value,
          }),
        },
      );

      if (!response.ok) throw new Error("Failed to update field");
      await fetchEmployeesForPayrun();
    } catch (error) {
      console.error("Error updating field:", error);
      throw error;
    }
  };

  const handleDeductionOverride = async (
    employee: Employee,
    deductionCode: string,
    value: string,
    overrideType: DeductionOverrideType,
  ) => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) throw new Error("No auth token found");

      const response = await fetch(
        `http://localhost:8000/employees/create_deduction_override/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            payrunLineItemVersion: employee.plivId,
            deductionCode,
            [overrideType]: value,
          }),
        },
      );

      if (!response.ok) throw new Error("Failed to update deduction");
      await fetchEmployeesForPayrun();
    } catch (error) {
      console.error("Error updating deduction:", error);
      throw error;
    }
  };

  const handleEarningOverrideRemoval = async (
    employee: Employee,
    earningCode: string,
    type: EarningOverrideType,
  ): Promise<string> => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) throw new Error("No auth token found");

      const response = await fetch(
        `http://localhost:8000/employees/remove_earning_override/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            payrunLineItemVersion: employee.plivId,
            earningCode,
            type,
          }),
        },
      );

      if (!response.ok) throw new Error("Failed to remove earning override");
      await fetchEmployeesForPayrun();
      return "0.00";
    } catch (error) {
      console.error("Error removing earning override:", error);
      throw error;
    }
  };

  const handleDeductionOverrideRemoval = async (
    employee: Employee,
    deductionCode: string,
    type: DeductionOverrideType,
  ): Promise<string> => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) throw new Error("No auth token found");

      const response = await fetch(
        `http://localhost:8000/employees/remove_deduction_override/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            payrunLineItemVersion: employee.plivId,
            deductionCode,
            type,
          }),
        },
      );

      if (!response.ok) throw new Error("Failed to remove deduction override");
      await fetchEmployeesForPayrun();
      return "0.00";
    } catch (error) {
      console.error("Error removing deduction override:", error);
      throw error;
    }
  };

  const handleProcessPayroll = () => {
    navigate(`/payroll-approval/${payrunId}`);
  };

  const handleRefreshPayrun = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) throw new Error("No auth token found");

      const response = await fetch(
        `http://localhost:8000/employees/payruns/${payrunId}/refresh/`,
        {
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (!response.ok) throw new Error("Failed to refresh payrun");
      await fetchEmployeesForPayrun();
    } catch (error) {
      console.error("Error refreshing payrun:", error);
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <MenuBar />
      <div className="flex-1 overflow-auto">
        <Card className="mt-6">
          <CardHeader>
            <h2 className="text-2xl font-bold">
              Earnings & Deductions Overview
            </h2>
          </CardHeader>
          <CardContent>
            <Tabs value={activeTab} onValueChange={setActiveTab}>
              <TabsList>
                <TabsTrigger value="earnings">Earnings</TabsTrigger>
                <TabsTrigger value="deductions">Deductions</TabsTrigger>
              </TabsList>

              <TabsContent value="earnings">
                <div className="rounded-md border">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="w-[50px]">
                          <Checkbox
                            checked={selected.length === employees.length}
                            onChange={(checked) => {
                              setSelected(
                                checked
                                  ? employees.map((e) => e.id.toString())
                                  : [],
                              );
                            }}
                          />
                        </TableHead>
                        <TableHead>Name</TableHead>
                        {earnings.map((earning) => (
                          <React.Fragment key={earning.internalEarningCode}>
                            <TableHead>{earning.displayName} Amount</TableHead>
                            <TableHead>{earning.displayName} Hours</TableHead>
                            {earning.customRateId && (
                              <TableHead>{earning.displayName} Rate</TableHead>
                            )}
                          </React.Fragment>
                        ))}
                        <TableHead className="w-[100px]">Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {employees.map((employee) => (
                        <TableRow key={employee.id}>
                          <TableCell>
                            <Checkbox
                              checked={selected.includes(
                                employee.id.toString(),
                              )}
                              onChange={(checked) => {
                                setSelected((ids) =>
                                  checked
                                    ? [...ids, employee.id.toString()]
                                    : ids.filter(
                                        (id) => id !== employee.id.toString(),
                                      ),
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            {employee.firstName} {employee.lastName}
                          </TableCell>
                          {earnings.map((earning) => {
                            const employeeEarning = employee.earnings.find(
                              (e) =>
                                e.earningCode === earning.internalEarningCode,
                            );
                            return (
                              <React.Fragment key={earning.internalEarningCode}>
                                <ModalEditableCell
                                  initialValue={
                                    employeeEarning?.amount.currentAmount ||
                                    "0.00"
                                  }
                                  isDisabled={!!earning.customRateId}
                                  onSave={(value) =>
                                    handleEarningOverride(
                                      employee,
                                      earning.internalEarningCode,
                                      value,
                                      "amount",
                                    )
                                  }
                                  onRemove={() =>
                                    handleEarningOverrideRemoval(
                                      employee,
                                      earning.internalEarningCode,
                                      "amount",
                                    )
                                  }
                                />
                                <ModalEditableCell
                                  initialValue={
                                    employeeEarning?.hours.currentAmount ||
                                    "0.00"
                                  }
                                  onSave={(value) =>
                                    handleEarningOverride(
                                      employee,
                                      earning.internalEarningCode,
                                      value,
                                      "hours",
                                    )
                                  }
                                  onRemove={() =>
                                    handleEarningOverrideRemoval(
                                      employee,
                                      earning.internalEarningCode,
                                      "hours",
                                    )
                                  }
                                />
                                {earning.customRateId && (
                                  <TableCell>
                                    {employeeEarning?.hours.currentRate ||
                                      "0.00"}
                                  </TableCell>
                                )}
                              </React.Fragment>
                            );
                          })}
                          <TableCell>
                            <Button variant="ghost" size="icon">
                              <MoreHorizontal className="h-4 w-4" />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </TabsContent>

              <TabsContent value="deductions">
                <div className="rounded-md border">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="w-[50px]">
                          <Checkbox
                            checked={selected.length === employees.length}
                            onChange={(checked) => {
                              setSelected(
                                checked
                                  ? employees.map((e) => e.id.toString())
                                  : [],
                              );
                            }}
                          />
                        </TableHead>
                        <TableHead>Name</TableHead>
                        {deductions.map((deduction) => (
                          <React.Fragment key={deduction.internalDeductionCode}>
                            <TableHead>
                              {deduction.displayName} Employee Amount
                            </TableHead>
                            <TableHead>
                              {deduction.displayName} Employer Amount
                            </TableHead>
                            <TableHead>
                              {deduction.displayName} Employee Percentage
                            </TableHead>
                            <TableHead>
                              {deduction.displayName} Employer Percentage
                            </TableHead>
                          </React.Fragment>
                        ))}
                        <TableHead className="w-[100px]">Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {employees.map((employee) => (
                        <TableRow key={employee.id}>
                          <TableCell>
                            <Checkbox
                              checked={selected.includes(
                                employee.id.toString(),
                              )}
                              onChange={(checked) => {
                                setSelected((ids) =>
                                  checked
                                    ? [...ids, employee.id.toString()]
                                    : ids.filter(
                                        (id) => id !== employee.id.toString(),
                                      ),
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            {employee.firstName} {employee.lastName}
                          </TableCell>
                          {deductions.map((deduction) => {
                            const employeeDeduction = employee.deductions.find(
                              (d) =>
                                d.deductionCode ===
                                deduction.internalDeductionCode,
                            );
                            return (
                              <React.Fragment
                                key={deduction.internalDeductionCode}
                              >
                                <ModalEditableCell
                                  initialValue={
                                    employeeDeduction?.employeeAmount || "0.00"
                                  }
                                  isDisabled={
                                    !deduction.employeeDeduction
                                      ?.isEEAmountEditable
                                  }
                                  onSave={(value) =>
                                    handleDeductionOverride(
                                      employee,
                                      deduction.internalDeductionCode,
                                      value,
                                      "employeeAmount",
                                    )
                                  }
                                  onRemove={() =>
                                    handleDeductionOverrideRemoval(
                                      employee,
                                      deduction.internalDeductionCode,
                                      "employeeAmount",
                                    )
                                  }
                                />
                                <ModalEditableCell
                                  initialValue={
                                    employeeDeduction?.employerAmount || "0.00"
                                  }
                                  isDisabled={
                                    !deduction.employeeDeduction
                                      ?.isERAmountEditable
                                  }
                                  onSave={(value) =>
                                    handleDeductionOverride(
                                      employee,
                                      deduction.internalDeductionCode,
                                      value,
                                      "employerAmount",
                                    )
                                  }
                                  onRemove={() =>
                                    handleDeductionOverrideRemoval(
                                      employee,
                                      deduction.internalDeductionCode,
                                      "employerAmount",
                                    )
                                  }
                                />
                                <ModalEditableCell
                                  initialValue={
                                    employeeDeduction?.employeePercentage ||
                                    "0.00"
                                  }
                                  isDisabled={
                                    !deduction.employeeDeduction
                                      ?.isEEPercentEditable
                                  }
                                  onSave={(value) =>
                                    handleDeductionOverride(
                                      employee,
                                      deduction.internalDeductionCode,
                                      value,
                                      "employeePercentage",
                                    )
                                  }
                                  onRemove={() =>
                                    handleDeductionOverrideRemoval(
                                      employee,
                                      deduction.internalDeductionCode,
                                      "employeePercentage",
                                    )
                                  }
                                />
                                <ModalEditableCell
                                  initialValue={
                                    employeeDeduction?.employerPercentage ||
                                    "0.00"
                                  }
                                  isDisabled={
                                    !deduction.employeeDeduction
                                      ?.isERPercentEditable
                                  }
                                  onSave={(value) =>
                                    handleDeductionOverride(
                                      employee,
                                      deduction.internalDeductionCode,
                                      value,
                                      "employerPercentage",
                                    )
                                  }
                                  onRemove={() =>
                                    handleDeductionOverrideRemoval(
                                      employee,
                                      deduction.internalDeductionCode,
                                      "employerPercentage",
                                    )
                                  }
                                />
                              </React.Fragment>
                            );
                          })}
                          <TableCell>
                            <Button variant="ghost" size="icon">
                              <MoreHorizontal className="h-4 w-4" />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </TabsContent>
            </Tabs>

            <div className="flex justify-end space-x-4 mt-6">
              <Button onClick={handleProcessPayroll}>Process Payroll</Button>
              <Button onClick={handleRefreshPayrun} variant="outline">
                Refresh Payrun
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default RunPayroll;
