import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Option as BaseOption, optionClasses } from "@mui/base/Option";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MenuBar from "@/components/MenuBar";
import LogTimeOffModal from "@/components/pto/LogTimeOffModal";
import { TooltipContent } from "@radix-ui/react-tooltip";
import { Tooltip, TooltipProvider, TooltipTrigger } from "./ui/tooltip";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { HelpCircle } from "lucide-react";
import { toast } from "@/components/ui/use-toast";
import { TableHeader } from "@/components/ui/table";
import { Button } from "@/components/ui/button";

interface PtoSettingsVersion {
  id: number;
  ukEmployerEmployeeId: number;
  ptoSettings: number;
  vacationPayConfiguration: string;
  version: number;
}

interface LeaveBalance {
  policyId: number;
  name: string;
  policyDisplayName: string;
  ukEmployerEmployeeId: number;
  leaveTypeVersionId: number;
  balance: number;
}

interface LeaveTypeVersion {
  id: number;
  version: number;
  countryCode: string;
  displayName: string;
  description: string;
  isUnpaid: boolean;
  leaveTypeId: number;
}

interface Leave {
  id: number;
  countryCode: string;
  startDate: string;
  reason?: string;
  ukEmployer: number;
  latestStatus: string;
  statusHistory: string[];
  hours?: number;
  endDate: string;
  leaveTypeVersion: LeaveTypeVersion;
  eeDisplayName: string;
}

interface LeavePolicy {
  policyId: number;
  policyDisplayName: string;
  accrualPeriod: string;
  canHaveNegativeBalance: boolean;
  accrualStartFrom: string | null;
  requiresProof: boolean;
}

const TimeOff: React.FC = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const defaultStartDate = `${currentYear}-01-01`;
  const defaultEndDate = `${currentYear}-12-31`;
  const [activeTab, setActiveTab] = useState<number>(0);
  const [leaveBalances, setLeaveBalances] = useState<LeaveBalance[]>([]);
  const [leaves, setLeaves] = useState<Leave[]>([]);
  const [filteredLeaves, setFilteredLeaves] = useState<Leave[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterEmployee, setFilterEmployee] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(defaultStartDate);
  const [filterEndDate, setFilterEndDate] = useState(defaultEndDate);
  const [filterStatus, setFilterStatus] = useState<string | null>(null);
  const [leavePolicies, setLeavePolicies] = useState<LeavePolicy[]>([]);
  const [employeePtoSettings, setEmployeePtoSettings] = useState<
    PtoSettingsVersion[]
  >([]);

  useEffect(() => {
    fetchLeaveBalances();
    fetchLeaves();
    fetchLeavePolicies();
    fetchPtoSettings();
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const fetchPtoSettings = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No auth token found");
      }

      const response = await fetch(
        "http://localhost:8000/employees/pto/settings/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch PTO settings");
      }

      const data = await response.json();
      setEmployeePtoSettings(data.ptoSettings);
    } catch (error) {
      console.error("Error fetching PTO settings:", error);
      toast({
        title: "Error",
        description: "Failed to fetch PTO settings",
        variant: "destructive",
      });
    }
  };

  const handleUpdatePtoSettings = async (
    employeeId: number,
    vacationPayConfig: string,
  ) => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No auth token found");
      }

      const response = await fetch(
        "http://localhost:8000/employees/pto/settings/create/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ukEmployerEmployee: employeeId,
            vacationPayConfiguration: vacationPayConfig,
          }),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to update vacation pay settings");
      }

      await fetchPtoSettings(); // Refresh settings after update
      toast({
        title: "Success",
        description: "Vacation pay settings updated successfully",
      });
    } catch (error) {
      console.error("Error updating settings:", error);
      toast({
        title: "Error",
        description: "Failed to update vacation pay settings",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    const filtered = leaves.filter((leave) => {
      const employeeMatch = leave.eeDisplayName
        .toLowerCase()
        .includes(filterEmployee.toLowerCase());
      const startDateMatch = filterStartDate
        ? new Date(leave.startDate) >= new Date(filterStartDate)
        : true;
      const endDateMatch = filterEndDate
        ? new Date(leave.endDate) <= new Date(filterEndDate)
        : true;
      const statusMatch =
        filterStatus && filterStatus !== "all"
          ? leave.latestStatus === filterStatus
          : true;
      return employeeMatch && startDateMatch && endDateMatch && statusMatch;
    });
    setFilteredLeaves(filtered);
  }, [filterEmployee, filterStartDate, filterEndDate, filterStatus, leaves]);

  const fetchLeaveBalances = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No auth token found");
      }

      const response = await fetch(
        "http://localhost:8000/employees/pto/policies/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch leave balances");
      }

      const data = await response.json();
      setLeaveBalances(data.leavePolicies);
    } catch (error) {
      console.error("Error fetching leave balances:", error);
    }
  };

  const fetchLeaves = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No auth token found");
      }

      const response = await fetch(
        `http://localhost:8000/employees/pto/get_leaves/?startDate=${filterStartDate}&endDate=${filterEndDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch leaves");
      }

      const data = await response.json();
      setLeaves(data.leaves);
      setFilteredLeaves(data.leaves);
    } catch (error) {
      console.error("Error fetching leaves:", error);
    }
  };

  const fetchLeavePolicies = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No auth token found");
      }

      const response = await fetch(
        "http://localhost:8000/employees/pto/policies/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch leave policies");
      }

      const data = await response.json();
      setLeavePolicies(data.leavePolicies);
    } catch (error) {
      console.error("Error fetching leave policies:", error);
    }
  };

  const handlePolicyClick = (policyId: number) => {
    navigate(`/time-off/policy/${policyId}`);
  };

  const SettingsTable = () => (
    <Card>
      <CardHeader>
        <div className="flex items-center space-x-2">
          <h3 className="text-lg font-semibold">Employee PTO Settings</h3>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <HelpCircle className="h-4 w-4 text-gray-500" />
              </TooltipTrigger>
              <TooltipContent className="w-80">
                <p>
                  Configure how vacation pay should be handled for each
                  employee:
                </p>
                <ul className="list-disc pl-4 mt-2">
                  <li>
                    <strong>Separate:</strong> Vacation pay calculated and paid
                    separately
                  </li>
                  <li>
                    <strong>Not Separate:</strong> Vacation pay included in
                    regular wages
                  </li>
                </ul>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>Employee Name</TableCell>
              <TableCell>Vacation Pay Configuration</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {employeePtoSettings.map((setting) => (
              <TableRow key={setting.ukEmployerEmployeeId}>
                <TableCell>{setting.ukEmployerEmployeeId}</TableCell>
                <TableCell>
                  <Select
                    defaultValue={setting.vacationPayConfiguration}
                    onValueChange={(value) =>
                      handleUpdatePtoSettings(
                        setting.ukEmployerEmployeeId,
                        value,
                      )
                    }
                  >
                    <SelectTrigger className="w-40">
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Separate">Separate</SelectItem>
                      <SelectItem value="NotSeparate">Not Separate</SelectItem>
                    </SelectContent>
                  </Select>
                </TableCell>
                <TableCell>
                  <Button
                    variant="secondary"
                    onClick={() =>
                      navigate(`/employee/${setting.ukEmployerEmployeeId}`)
                    }
                  >
                    View Employee
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );

  return (
    <div className="flex h-screen bg-gray-100">
      <MenuBar />
      <div className="flex-1 overflow-auto">
        <div className="p-8">
          <div className="flex items-center justify-between mb-8">
            <Typography variant="h4" component="h1">
              Time Off
            </Typography>
          </div>
          <Card>
            <CardContent>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                className="mb-4"
              >
                <Tab label="Time Off" />
                <Tab label="Policies" />
                <Tab label="Settings" />
              </Tabs>

              {activeTab === 0 && (
                <>
                  <Card className="mb-6">
                    <CardHeader
                      title="Time Off Balances"
                      action={
                        <Button
                          variant="default" // Changed from "contained"
                          onClick={() => setIsModalOpen(true)}
                        >
                          Log Time Off
                        </Button>
                      }
                    />
                    <CardContent>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Employee Name</TableCell>
                              <TableCell>Leave Policy</TableCell>
                              <TableCell>Balance</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {leaveBalances.map((balance, index) => (
                              <TableRow key={index}>
                                <TableCell>{balance.name}</TableCell>
                                <TableCell>
                                  {balance.policyDisplayName}
                                </TableCell>
                                <TableCell>{balance.balance}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>

                  <Card>
                    <CardHeader title="All Leaves" />
                    <CardContent>
                      <Box className="flex flex-wrap gap-4 mb-4">
                        <TextField
                          label="Filter by employee name"
                          value={filterEmployee}
                          onChange={(e) => setFilterEmployee(e.target.value)}
                          className="max-w-sm"
                        />
                        <TextField
                          type="date"
                          label="Start date"
                          value={filterStartDate}
                          onChange={(e) => setFilterStartDate(e.target.value)}
                          className="max-w-sm"
                          InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                          type="date"
                          label="End date"
                          value={filterEndDate}
                          onChange={(e) => setFilterEndDate(e.target.value)}
                          className="max-w-sm"
                          InputLabelProps={{ shrink: true }}
                        />
                        {/* Add status filter here if needed */}
                      </Box>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Employee Name</TableCell>
                              <TableCell>Leave Type</TableCell>
                              <TableCell>Start Date</TableCell>
                              <TableCell>End Date</TableCell>
                              <TableCell>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredLeaves.map((leave, index) => (
                              <TableRow key={index}>
                                <TableCell>{leave.eeDisplayName}</TableCell>
                                <TableCell>
                                  {leave.leaveTypeVersion.displayName}
                                </TableCell>
                                <TableCell>{leave.startDate}</TableCell>
                                <TableCell>{leave.endDate}</TableCell>
                                <TableCell>{leave.latestStatus}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </>
              )}

              {activeTab === 1 && (
                <Card>
                  <CardHeader title="Leave Policies" />
                  <CardContent>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Policy Name</TableCell>
                            <TableCell>Accrual Period</TableCell>
                            <TableCell>Negative Balance Allowed</TableCell>
                            <TableCell>Accrual Start</TableCell>
                            <TableCell>Requires Proof</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {leavePolicies.map((policy) => (
                            <TableRow
                              key={policy.policyId}
                              onClick={() =>
                                navigate(`/time-off/policy/${policy.policyId}`)
                              }
                              className="cursor-pointer hover:bg-gray-100"
                            >
                              <TableCell>{policy.policyDisplayName}</TableCell>
                              <TableCell>{policy.accrualPeriod}</TableCell>
                              <TableCell>
                                {policy.canHaveNegativeBalance ? "Yes" : "No"}
                              </TableCell>
                              <TableCell>
                                {policy.accrualStartFrom || "N/A"}
                              </TableCell>
                              <TableCell>
                                {policy.requiresProof ? "Yes" : "No"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              )}
              {activeTab === 2 && <SettingsTable />}
            </CardContent>
          </Card>
        </div>
      </div>

      <LogTimeOffModal
        leaveBalances={leaveBalances}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default TimeOff;
