import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { CalendarIcon, Edit, PlusCircle, Trash2 } from "lucide-react";
import { format, parse, isAfter, isBefore } from "date-fns";
import { cn } from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { toast } from "@/components/ui/use-toast";

interface JobEntry {
  id: number;
  jobEntryPeriodStartDate: string;
  jobEntryExpirationDate: string | null;
  salary: number;
  salaryUnit: "Yearly" | "Monthly" | "Weekly" | "Hourly";
  paySchedule: "Monthly" | "Weekly";
}

interface JobInformationProps {
  employeeId?: string;
}

const JobInformation: React.FC<JobInformationProps> = ({ employeeId }) => {
  const [jobTimeline, setJobTimeline] = useState<JobEntry[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingEntry, setEditingEntry] = useState<JobEntry | null>(null);
  const [finalStartDate, setFinalStartDate] = useState<string | null>(null);
  const [finalEndDate, setFinalEndDate] = useState<string | null>(null);

  useEffect(() => {
    fetchJobTimeline();
  }, [employeeId]);

  useEffect(() => {
    updateFinalDates();
  }, [jobTimeline]);

  const formatDate = (date: Date) => {
    return date.toISOString().split(".")[0] + "Z";
  };

  const fetchJobTimeline = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No auth token found");
      }
      const response = await fetch(
        `http://localhost:8000/employees/get_job_timeline/${employeeId}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (!response.ok) throw new Error("Failed to fetch job timeline");
      const data = await response.json();
      setJobTimeline(data);
    } catch (error) {
      console.error("Error fetching job timeline:", error);
      toast({
        title: "Error",
        description: "Failed to fetch job timeline",
        variant: "destructive",
      });
    }
  };

  const validateEntry = (entry: JobEntry, index: number): boolean => {
    const prevEntry = jobTimeline[index - 1];
    const nextEntry = jobTimeline[index + 1];
    if (
      prevEntry &&
      prevEntry.paySchedule !== entry.paySchedule &&
      new Date(entry.jobEntryPeriodStartDate).getDate() !== 1
    ) {
      toast({
        title: "Invalid Entry",
        description:
          "You can only change the pay schedule on the first of the month",
        variant: "destructive",
      });
      return false;
    }
    if (
      prevEntry &&
      isBefore(
        new Date(entry.jobEntryPeriodStartDate),
        new Date(prevEntry.jobEntryPeriodStartDate),
      )
    ) {
      toast({
        title: "Invalid Entry",
        description:
          "Job entry period start date cannot be before the previous entry's start date.",
        variant: "destructive",
      });
      return false;
    }

    if (
      nextEntry &&
      isAfter(
        new Date(entry.jobEntryPeriodStartDate),
        new Date(nextEntry.jobEntryPeriodStartDate),
      )
    ) {
      toast({
        title: "Invalid Entry",
        description:
          "Job entry period start date cannot be after the next entry's start date.",
        variant: "destructive",
      });
      return false;
    }

    if (entry.jobEntryExpirationDate) {
      if (
        isBefore(
          new Date(entry.jobEntryExpirationDate),
          new Date(entry.jobEntryPeriodStartDate),
        )
      ) {
        toast({
          title: "Invalid Entry",
          description:
            "Job entry expiration date cannot be before the start date.",
          variant: "destructive",
        });
        return false;
      }

      if (
        nextEntry &&
        isAfter(
          new Date(entry.jobEntryExpirationDate),
          new Date(nextEntry.jobEntryPeriodStartDate),
        )
      ) {
        toast({
          title: "Invalid Entry",
          description:
            "Job entry expiration date cannot be after the next entry's start date.",
          variant: "destructive",
        });
        return false;
      }
    }

    return true;
  };

  const updateFinalDates = () => {
    if (jobTimeline.length > 0) {
      const sortedTimeline = [...jobTimeline].sort(
        (a, b) =>
          new Date(a.jobEntryPeriodStartDate).getTime() -
          new Date(b.jobEntryPeriodStartDate).getTime(),
      );
      setFinalStartDate(sortedTimeline[0].jobEntryPeriodStartDate);
      const lastEntry = sortedTimeline[sortedTimeline.length - 1];
      setFinalEndDate(lastEntry.jobEntryExpirationDate || null);
    } else {
      setFinalStartDate(null);
      setFinalEndDate(null);
    }
  };

  const handleEditEntry = (entry: JobEntry) => {
    setEditingEntry({ ...entry });
    setIsEditing(true);
  };

  const handleUpdateEntry = () => {
    if (editingEntry) {
      const index = jobTimeline.findIndex(
        (entry) => entry.id === editingEntry.id,
      );
      if (validateEntry(editingEntry, index)) {
        setJobTimeline((timeline) =>
          timeline.map((entry) =>
            entry.id === editingEntry.id ? editingEntry : entry,
          ),
        );
        setIsEditing(false);
        setEditingEntry(null);
      }
    }
  };

  const handleAddEntry = () => {
    const lastEntry = jobTimeline[jobTimeline.length - 1];
    const newStartDate = lastEntry
      ? new Date(
          lastEntry.jobEntryExpirationDate || lastEntry.jobEntryPeriodStartDate,
        )
      : new Date();
    newStartDate.setDate(newStartDate.getDate() + 1); // Set to the next day
    const formattedDate = formatDate(newStartDate);

    const newEntry: JobEntry = {
      id: Date.now(),
      jobEntryPeriodStartDate: formattedDate,
      jobEntryExpirationDate: null,
      salary: 0,
      salaryUnit: "Yearly",
      paySchedule: "Monthly",
    };

    if (validateEntry(newEntry, jobTimeline.length)) {
      setJobTimeline([...jobTimeline, newEntry]);
    }
  };

  const handleRemoveEntry = (id: number) => {
    setJobTimeline((timeline) => timeline.filter((entry) => entry.id !== id));
  };

  const handleSubmitTimeline = async () => {
    try {
      const response = await fetch(
        `http://localhost:8000/employees/update_job_timeline/${employeeId}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          body: JSON.stringify(
            jobTimeline.map((jobEntry) => {
              jobEntry.jobEntryPeriodStartDate = formatDate(
                new Date(jobEntry.jobEntryPeriodStartDate),
              );
              jobEntry.jobEntryExpirationDate = jobEntry.jobEntryExpirationDate
                ? (jobEntry.jobEntryExpirationDate = formatDate(
                    new Date(jobEntry.jobEntryExpirationDate),
                  ))
                : null;
              return jobEntry;
            }),
          ),
        },
      );

      if (!response.ok) throw new Error("Failed to update job timeline");

      await response.json();
      toast({
        title: "Success",
        description: "Job timeline updated successfully",
      });
      fetchJobTimeline();
    } catch (error) {
      console.error("Error updating job timeline:", error);
      toast({
        title: "Error",
        description:
          error instanceof Error
            ? error.message
            : "Failed to update job timeline",
        variant: "destructive",
      });
    }
  };

  return (
    <div>
      <div className="mb-4 p-4 bg-gray-100 rounded-md mt-6">
        <h3 className="text-lg font-semibold mb-2">Employment Period</h3>
        <p>
          Start Date:{" "}
          {finalStartDate ? format(new Date(finalStartDate), "PP") : "Not set"}
        </p>
        <p>
          End Date:{" "}
          {finalEndDate ? format(new Date(finalEndDate), "PP") : "Ongoing"}
        </p>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Job Entry Period Start Date</TableHead>
            <TableHead>Job Entry Expiration Date</TableHead>
            <TableHead>Salary</TableHead>
            <TableHead>Salary Unit</TableHead>
            <TableHead>Pay Schedule</TableHead>
            <TableHead className="text-center">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {jobTimeline.map((entry) => (
            <TableRow key={entry.id}>
              <TableCell>
                {format(new Date(entry.jobEntryPeriodStartDate), "PP")}
              </TableCell>
              <TableCell>
                {entry.jobEntryExpirationDate
                  ? format(new Date(entry.jobEntryExpirationDate), "PP")
                  : "N/A"}
              </TableCell>
              <TableCell>{entry.salary}</TableCell>
              <TableCell>{entry.salaryUnit}</TableCell>
              <TableCell>{entry.paySchedule}</TableCell>
              <TableCell className="text-center">
                <div className="flex justify-center space-x-2">
                  <Button
                    onClick={() => handleEditEntry(entry)}
                    variant="ghost"
                    size="icon"
                  >
                    <Edit className="h-4 w-4" />
                    <span className="sr-only">Edit</span>
                  </Button>
                  <Button
                    onClick={() => handleRemoveEntry(entry.id)}
                    variant="ghost"
                    size="icon"
                  >
                    <Trash2 className="h-4 w-4" />
                    <span className="sr-only">Delete</span>
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="mt-4 flex justify-end space-x-2">
        <Button onClick={handleAddEntry}>
          <PlusCircle className="mr-2 h-4 w-4" />
          Add New Entry
        </Button>
        <Button onClick={handleSubmitTimeline}>Submit Timeline</Button>
      </div>

      <Dialog open={isEditing} onOpenChange={setIsEditing}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Job Entry</DialogTitle>
          </DialogHeader>
          {editingEntry && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateEntry();
              }}
              className="space-y-4"
            >
              <div className="space-y-2">
                <Label htmlFor="jobEntryPeriodStartDate">
                  Job Entry Period Start Date
                </Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant={"outline"}
                      className={cn(
                        "w-full justify-start text-left font-normal",
                        !editingEntry.jobEntryPeriodStartDate &&
                          "text-muted-foreground",
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {editingEntry.jobEntryPeriodStartDate ? (
                        format(
                          new Date(editingEntry.jobEntryPeriodStartDate),
                          "PP",
                        )
                      ) : (
                        <span>Pick a date</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <Calendar
                      mode="single"
                      selected={new Date(editingEntry.jobEntryPeriodStartDate)}
                      onSelect={(date) =>
                        setEditingEntry({
                          ...editingEntry,
                          jobEntryPeriodStartDate: date
                            ? date.toISOString()
                            : "",
                        })
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
              </div>
              <div className="space-y-2">
                <Label htmlFor="jobEntryExpirationDate">
                  Job Entry Expiration Date
                </Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant={"outline"}
                      className={cn(
                        "w-full justify-start text-left font-normal",
                        !editingEntry.jobEntryExpirationDate &&
                          "text-muted-foreground",
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {editingEntry.jobEntryExpirationDate ? (
                        format(
                          new Date(editingEntry.jobEntryExpirationDate),
                          "PP",
                        )
                      ) : (
                        <span>Pick a date</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <Calendar
                      mode="single"
                      selected={
                        editingEntry.jobEntryExpirationDate
                          ? new Date(editingEntry.jobEntryExpirationDate)
                          : undefined
                      }
                      onSelect={(date) =>
                        setEditingEntry({
                          ...editingEntry,
                          jobEntryExpirationDate: date
                            ? date.toISOString()
                            : null,
                        })
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
              </div>
              <div className="space-y-2">
                <Label htmlFor="salary">Salary</Label>
                <Input
                  id="salary"
                  type="number"
                  value={editingEntry.salary}
                  onChange={(e) =>
                    setEditingEntry({
                      ...editingEntry,
                      salary: parseFloat(e.target.value),
                    })
                  }
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="salaryUnit">Salary Unit</Label>
                <Select
                  value={editingEntry.salaryUnit}
                  onValueChange={(
                    value: "Yearly" | "Monthly" | "Weekly" | "Hourly",
                  ) => setEditingEntry({ ...editingEntry, salaryUnit: value })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select salary unit" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Yearly">Yearly</SelectItem>
                    <SelectItem value="Monthly">Monthly</SelectItem>
                    <SelectItem value="Weekly">Weekly</SelectItem>
                    <SelectItem value="Hourly">Hourly</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="paySchedule">Pay Schedule</Label>
                <Select
                  value={editingEntry.paySchedule}
                  onValueChange={(value: "Monthly" | "Weekly") =>
                    setEditingEntry({ ...editingEntry, paySchedule: value })
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select pay schedule" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Monthly">Monthly</SelectItem>
                    <SelectItem value="Weekly">Weekly</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <Button type="submit">Update</Button>
            </form>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default JobInformation;
