import React, { useState, useEffect } from "react";
import { Button } from "./ui/button";
import { useParams } from "react-router-dom";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import MenuBar from "@/components/MenuBar";
import DemographicInformation from "./employee/DemographicInformation";
import JobInformation from "./employee/JobInformation";
import TaxInformation from "./employee/TaxInformation";
import { useToast } from "@/components/ui/use-toast";
import {
  EmployeeDemographicInfo,
  EmployeeTaxInfo,
  EmployeeJobInfo,
} from "./types/employeeTypes";
import ImportInformation from "@/components/ImportInformation";

const EmployeeOverview: React.FC = () => {
  const { toast } = useToast();
  const { employeeId } = useParams<{ employeeId: string }>();
  const [demographicInfo, setDemographicInfo] =
    useState<EmployeeDemographicInfo | null>(null);
  const [taxInfo, setTaxInfo] = useState<EmployeeTaxInfo | null>({
    id: null,
    nationalInsuranceNumber: null,
    nationalInsuranceLetter: "A",
    hasHadPreviousJobInFiscalYear: false,
    previousEmployerIncomeYTD: 0,
    previousEmployerIncomeTaxYTD: 0,
    starterDeclaration: null,
    taxRegime: "",
    taxCode: "1257L",
    isCumulative: false,
    hasStudentLoan: false,
    studentLoanPlan: null,
    payId: "1",
    hasPostgradLoan: false,
  });
  const [jobInfo, setJobInfo] = useState<EmployeeJobInfo>({
    job: null,
    startDate: "",
    endDate: null,
    salary: 0,
    salaryUnit: "Yearly",
    payschedule: "Monthly",
    actualEffectiveDate: null,
    actualExpirationDate: null,
    recordedEffectiveTime: null,
  });

  useEffect(() => {
    fetchEmployeeData();
  }, [employeeId]);

  const fetchEmployeeData = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No auth token found");
      }

      const response = await fetch(
        `http://localhost:8000/employees/get_employee_demographic_information/${employeeId}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch employee data");
      }

      const data = await response.json();
      setDemographicInfo(data.demographicInfo);
      if (data.taxInfo && Object.keys(data.taxInfo).length > 0) {
        setTaxInfo(data.taxInfo);
      }
      if (data.jobInfo) {
        setJobInfo(data.jobInfo);
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <MenuBar />
      <div className="flex-1 overflow-auto">
        <div className="p-8">
          <div className="flex items-center justify-between mb-8">
            <h1 className="text-3xl font-semibold">Employee Overview</h1>
            <Button>Edit Employee</Button>
          </div>
          <Card>
            <CardHeader>
              <div className="flex justify-between items-center">
                <h2 className="text-2xl font-semibold">
                  {demographicInfo
                    ? `${demographicInfo.firstName} ${demographicInfo.lastName}`
                    : "Loading..."}
                </h2>
              </div>
            </CardHeader>
            <CardContent>
              <Tabs defaultValue="demographic">
                <TabsList className="mb-4">
                  <TabsTrigger value="demographic">
                    Demographic Information
                  </TabsTrigger>
                  <TabsTrigger value="job">Job Information</TabsTrigger>
                  {jobInfo && jobInfo.job && (
                    <TabsTrigger value="tax">Tax Information</TabsTrigger>
                  )}
                  {employeeId && (
                    <TabsTrigger value="imports">Previous Payroll</TabsTrigger>
                  )}
                </TabsList>

                <TabsContent value="demographic">
                  <DemographicInformation
                    demographicInfo={demographicInfo}
                    setDemographicInfo={setDemographicInfo}
                    employeeId={employeeId}
                    toast={toast}
                  />
                </TabsContent>
                <TabsContent value="job">
                  <JobInformation employeeId={employeeId} />
                </TabsContent>
                <TabsContent value="tax">
                  <TaxInformation
                    taxInfo={taxInfo}
                    setTaxInfo={setTaxInfo}
                    employeeId={employeeId}
                    toast={toast}
                  />
                </TabsContent>
                {employeeId && (
                  <TabsContent value="imports">
                    <ImportInformation employeeId={employeeId} />
                  </TabsContent>
                )}
              </Tabs>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EmployeeOverview;
