import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";

interface Payrun {
  id: number;
  countryCode: string;
  payPeriodStartDate: string;
  payPeriodEndDate: string;
  payPeriodNumber: number;
  employer: number;
  payDate: string;
  status: string;
}

interface PayrunResponse {
  payruns: Payrun[];
}

const PayrunOverview: React.FC = () => {
  const [payruns, setPayruns] = useState<Payrun[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPayruns();
  }, []);

  const fetchPayruns = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No auth token found");
      }

      const response = await fetch("http://localhost:8000/employees/payruns/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch payruns");
      }

      const data: PayrunResponse = await response.json();
      setPayruns(data.payruns);
    } catch (error) {
      console.error("Error fetching payruns:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRunPayroll = (payrunId: number) => {
    navigate(`/run-payroll/${payrunId}`);
  };

  const handleCreatePayrun = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No auth token found");
      }

      const response = await fetch(
        "http://localhost:8000/employees/create_payruns/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      if (!response.ok) {
        throw new Error("Failed to create payrun");
      }

      await fetchPayruns();
    } catch (error) {
      console.error("Error creating payrun:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString: string): string => {
    // Parse the UTC date string and create a new Date object
    const date = new Date(dateString);
    // Format the date to YYYY-MM-DD
    return date.toISOString().split("T")[0];
  };

  return (
    <Card sx={{ mt: 6 }}>
      <CardHeader title={<Typography variant="h5">Payruns</Typography>} />
      <CardContent>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : payruns.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Pay Period Start</TableCell>
                <TableCell>Pay Period End</TableCell>
                <TableCell>Pay Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payruns.map((payrun) => (
                <TableRow key={payrun.id}>
                  <TableCell>{formatDate(payrun.payPeriodStartDate)}</TableCell>
                  <TableCell>{formatDate(payrun.payPeriodEndDate)}</TableCell>
                  <TableCell>{formatDate(payrun.payDate)}</TableCell>
                  <TableCell>{payrun.status}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => handleRunPayroll(payrun.id)}
                    >
                      Run Payroll
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Box textAlign="center">
            <Typography variant="body1" sx={{ mb: 2 }}>
              No payruns available.
            </Typography>
            <Button
              variant="contained"
              onClick={handleCreatePayrun}
              disabled={isLoading}
            >
              Create Payrun
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default PayrunOverview;
