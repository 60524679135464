import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Box,
  Chip,
  SelectChangeEvent,
} from "@mui/material";
import EmployeeDeductions from "@/components/earnings/interfaces";

interface AddCustomRateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (rate: CustomRate) => void;
  availableRateTypes: string[];
  availableDependentDeductions: EmployeeDeductions | null;
}

interface CustomRate {
  id?: number;
  name: string;
  rateType: string;
  unitType: string;
  multiplier?: number;
  fixedAmount?: number;
  dependentDeductionIds?: string[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const unitTypes = [
  { value: "hourly", label: "Hourly" },
  { value: "day", label: "Day" },
  { value: "weekend_day", label: "Weekend Day" },
  { value: "holiday_day", label: "Holiday Day" },
];

const AddCustomRateModal: React.FC<AddCustomRateModalProps> = ({
  isOpen,
  onClose,
  onAdd,
  availableRateTypes,
  availableDependentDeductions,
}) => {
  const [rate, setRate] = useState<CustomRate>({
    name: "",
    rateType: "",
    unitType: "",
    multiplier: undefined,
    fixedAmount: undefined,
    dependentDeductionIds: availableDependentDeductions?.deductions.map((d) =>
      d.id.toString(),
    ),
  });

  const handleChange = (field: keyof CustomRate, value: any) => {
    setRate((prev) => ({ ...prev, [field]: value }));
  };

  const handleDeductionChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    handleChange(
      "dependentDeductionIds",
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const handleSubmit = () => {
    if (
      rate.name &&
      rate.rateType &&
      rate.unitType &&
      (rate.multiplier || rate.fixedAmount)
    ) {
      onAdd(rate);
      onClose();
    } else {
      alert("Please fill in all required fields");
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Custom Rate</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 2 }}>
          <TextField
            label="Name"
            value={rate.name}
            onChange={(e) => handleChange("name", e.target.value)}
            fullWidth
            required
          />

          <FormControl fullWidth required>
            <InputLabel>Rate Type</InputLabel>
            <Select
              value={rate.rateType}
              onChange={(e) => handleChange("rateType", e.target.value)}
              label="Rate Type"
            >
              {availableRateTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth required>
            <InputLabel>Unit Type</InputLabel>
            <Select
              value={rate.unitType}
              onChange={(e) => handleChange("unitType", e.target.value)}
              label="Unit Type"
            >
              {unitTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Dependent Deductions</InputLabel>
            <Select
              multiple
              value={
                rate.dependentDeductionIds ||
                availableDependentDeductions?.deductions.map((d) =>
                  d.id.toString(),
                ) ||
                []
              }
              onChange={handleDeductionChange}
              input={<OutlinedInput label="Dependent Deductions" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => {
                    const deduction =
                      availableDependentDeductions?.deductions.find(
                        (d) => d.id.toString() === value,
                      );
                    return (
                      <Chip
                        key={value}
                        label={deduction?.displayName}
                        size="small"
                      />
                    );
                  })}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {availableDependentDeductions?.deductions.map((deduction) => (
                <MenuItem key={deduction.id} value={deduction.id.toString()}>
                  {deduction.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Multiplier"
            type="number"
            value={rate.multiplier || ""}
            onChange={(e) =>
              handleChange("multiplier", parseFloat(e.target.value))
            }
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            label="Fixed Amount"
            type="number"
            value={rate.fixedAmount || ""}
            onChange={(e) =>
              handleChange("fixedAmount", parseFloat(e.target.value))
            }
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">
          Add Rate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCustomRateModal;
