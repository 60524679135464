import React from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import HomeIcon from "@mui/icons-material/Home";
import DomainIcon from "@mui/icons-material/Domain";
import PeopleIcon from "@mui/icons-material/People";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SavingsIcon from "@mui/icons-material/Savings";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import FeedbackIcon from "@mui/icons-material/Feedback";
import LogoutIcon from "@mui/icons-material/Logout";
import { CurrencyPound, Folder } from "@mui/icons-material";

const MenuContainer = styled(Box)(({ theme }) => ({
  width: 250,
  backgroundColor: "#1a1d21",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  color: "white",
}));

const MenuLink = styled(Link)({
  color: "white",
  textDecoration: "none",
});

interface MenuItemProps {
  to?: string;
  icon: React.ReactElement;
  text: string;
  onClick?: () => void;
}

const MenuBar: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear authentication tokens
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");

    // Redirect to login page
    navigate("/login");
  };

  return (
    <MenuContainer>
      <Box sx={{ p: 2, borderBottom: "1px solid rgba(255,255,255,0.1)" }}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          Pay My Nanny
        </Typography>
      </Box>
      <List sx={{ flexGrow: 1, py: 0 }}>
        <MenuItem to="/" icon={<HomeIcon />} text="Home" />
        <MenuItem
          to="/employer-management"
          icon={<DomainIcon />}
          text="Employer Management"
        />
        <MenuItem
          to="/employee-management"
          icon={<PeopleIcon />}
          text="Employee Management"
        />
        <MenuItem to="/pensions" icon={<SavingsIcon />} text="Pensions" />
        <MenuItem to="/filings/employer" icon={<Folder />} text="Filings" />
        <MenuItem to="/earnings" icon={<CurrencyPound />} text="Earnings" />
        <MenuItem to="/time-off" icon={<CalendarTodayIcon />} text="Time Off" />
      </List>
      <Divider sx={{ bgcolor: "rgba(255,255,255,0.1)" }} />
      <List sx={{ py: 0 }}>
        <MenuItem to="/settings" icon={<SettingsIcon />} text="Settings" />
        <MenuItem to="/about" icon={<InfoIcon />} text="About" />
        <MenuItem to="/feedback" icon={<FeedbackIcon />} text="Feedback" />
        <MenuItem icon={<LogoutIcon />} text="Logout" onClick={handleLogout} />
      </List>
      <Box sx={{ p: 2, borderTop: "1px solid rgba(255,255,255,0.1)" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar sx={{ mr: 1 }} />
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2">Riley Carter</Typography>
            <Typography variant="body2" sx={{ color: "rgba(255,255,255,0.7)" }}>
              riley@email.com
            </Typography>
          </Box>
        </Box>
      </Box>
    </MenuContainer>
  );
};

const MenuItem: React.FC<MenuItemProps> = ({ to, icon, text, onClick }) => {
  const content = (
    <ListItemButton sx={{ py: 1 }} onClick={onClick}>
      <ListItemIcon sx={{ color: "white", minWidth: 40 }}>{icon}</ListItemIcon>
      <ListItemText
        primary={text}
        primaryTypographyProps={{ variant: "body2" }}
      />
    </ListItemButton>
  );

  if (to) {
    return <MenuLink to={to}>{content}</MenuLink>;
  }

  return content;
};

export default MenuBar;
