import React, { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { EmployeeDemographicInfo } from "../types/employeeTypes";

interface DemographicInformationProps {
  demographicInfo: EmployeeDemographicInfo | null;
  setDemographicInfo: React.Dispatch<
    React.SetStateAction<EmployeeDemographicInfo | null>
  >;
  employeeId: string | undefined;
  toast: (props: {
    title: string;
    description: string;
    duration?: number;
  }) => void;
}

const DemographicInformation: React.FC<DemographicInformationProps> = ({
  demographicInfo,
  setDemographicInfo,
  employeeId,
  toast,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleDemographicInfoChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setDemographicInfo((prev) => {
      if (!prev) return null;
      let updatedInfo;
      if (name.startsWith("address.")) {
        const addressField = name.split(".")[1];
        updatedInfo = {
          ...prev,
          homeAddress: {
            ...prev.homeAddress,
            [addressField]: value,
          },
        };
      } else {
        updatedInfo = { ...prev, [name]: value };
      }
      validateField(name, value);
      return updatedInfo;
    });
  };

  const validateField = (name: string, value: string) => {
    let error = "";
    switch (name) {
      case "firstName":
      case "lastName":
        if (value.trim() === "") {
          error = "This field is required";
        }
        if (!validateName(value)) {
          error = "Invalid name. You can only use A-Z characters.";
        }
        break;
      case "address.street_number":
        if (!validateStreetNumber(value)) {
          error =
            "Invalid street number. You can only provide numbers or letters.";
        }
        break;
      case "address.route":
      case "address.locality":
        if (value.trim() === "") {
          error = "This field is required";
        }
        break;
      case "address.postal_code":
        if (!validatePostcode(value)) {
          error = "Invalid postcode format";
        }
        break;
    }
    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const validateName = (name: string) => {
    const nameRegex = /^[A-Za-z]+$/;
    return nameRegex.test(name);
  };

  const validateStreetNumber = (streetNumber: string) => {
    const streetNumberRegex = /^[0-9A-Za-z]+$/;
    return streetNumberRegex.test(streetNumber);
  };

  const validatePostcode = (postcode: string) => {
    postcode = postcode.replace(/\s/g, "");
    const postcodeRegex = /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/;
    return postcodeRegex.test(postcode);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No auth token found");
      }

      if (demographicInfo) {
        if (!validatePostcode(demographicInfo.homeAddress.postal_code)) {
          toast({
            title: "Error",
            description: "Invalid postcode format",
            duration: 3000,
          });
          return;
        }

        if (
          demographicInfo.homeAddress.street_number.length < 1 ||
          demographicInfo.homeAddress.route.length < 1 ||
          demographicInfo.homeAddress.locality.length < 1
        ) {
          toast({
            title: "Error",
            description:
              "Street number, street name, and town/city are required",
            duration: 3000,
          });
          return;
        }
      }

      const url = employeeId
        ? `http://localhost:8000/employees/update_employee_personal_information/${employeeId}/`
        : `http://localhost:8000/employees/create_employee_personal_information/`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(demographicInfo),
      });

      if (!response.ok) {
        throw new Error("Failed to update demographic information");
      }

      console.log("Demographic information updated successfully");
      toast({
        title: "Success",
        description:
          "The demographic information has been updated successfully",
        duration: 3000,
      });
    } catch (error) {
      console.error("Error updating demographic information:", error);
    }
  };

  const renderInput = (
    name: string,
    label: string,
    value: string,
    required: boolean = false,
    type: string = "text",
  ) => (
    <div>
      <Label htmlFor={name}>
        {label}
        {required && <span className="text-red-500 ml-1">*</span>}
      </Label>
      <Input
        id={name}
        name={name}
        value={value}
        onChange={handleDemographicInfoChange}
        required={required}
        type={type}
      />
      {errors[name] && (
        <p className="text-red-500 text-sm mt-1">{errors[name]}</p>
      )}
    </div>
  );

  if (!demographicInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-2xl font-semibold mb-4">Demographic Information</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {renderInput(
              "firstName",
              "First Name",
              demographicInfo.firstName,
              true,
            )}
            {renderInput(
              "lastName",
              "Last Name",
              demographicInfo.lastName,
              true,
            )}
            {renderInput(
              "address.street_number",
              "Street Number",
              demographicInfo.homeAddress.street_number,
              true,
            )}
            {renderInput(
              "address.route",
              "Street Name",
              demographicInfo.homeAddress.route,
              true,
            )}
            {renderInput(
              "address.locality",
              "Town/City",
              demographicInfo.homeAddress.locality,
              true,
            )}
            {renderInput(
              "address.state",
              "County",
              demographicInfo.homeAddress.state,
            )}
            {renderInput(
              "address.postal_code",
              "Postcode",
              demographicInfo.homeAddress.postal_code,
              true,
            )}
            {renderInput(
              "address.country",
              "Country",
              demographicInfo.homeAddress.country || "United Kingdom",
              true,
            )}
            {renderInput(
              "phoneNumber",
              "Phone Number",
              demographicInfo.phoneNumber || "",
            )}

            <div className="space-y-2">
              <Label htmlFor="gender">
                Gender
                <span className="text-red-500 ml-1">*</span>
              </Label>
              <Select
                name="gender"
                onValueChange={(value) =>
                  handleDemographicInfoChange({
                    target: { name: "gender", value },
                  } as any)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select gender" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="M">Male</SelectItem>
                  <SelectItem value="F">Female</SelectItem>
                  <SelectItem value="O">Other</SelectItem>
                </SelectContent>
              </Select>
              {errors.gender && (
                <p className="text-red-500 text-sm">{errors.gender}</p>
              )}
            </div>

            {renderInput(
              "dob",
              "Date of Birth",
              demographicInfo.dob || "",
              true,
              "date",
            )}
          </div>

          <Button type="submit" className="w-full">
            Update Demographic Information
          </Button>
        </form>
      </div>
    </div>
  );
};
export default DemographicInformation;
