import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";
import PayrollLogin from "./components/PayrollLogin";
import Dashboard from "./components/Dashboard";
import EmployeeManagement from "./components/EmployeeManagement";
import EmployerManagement from "./components/EmployerManagement";
import Signup from "./components/Signup";
import EmployeeOverview from "@/components/EmployeeOverview";
import RunPayroll from "@/components/PayrunRun";
import { Toaster } from "@/components/ui/toaster";
import TimeOff from "@/components/TimeOff";
import PolicyConfig from "@/components/pto/PolicyConfig";
import Earnings from "@/components/Earnings";
import PayrollApproval from "@/components/PayrunApproval";
import LandingPage from "@/components/LandingPage";
import PensionsOverview from "@/components/pensions/PensionsOverview";
import FilingsGrid from "@/components/FilingsGrid";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem("authToken");
  return isAuthenticated ? <>{children}</> : <Navigate to="/login" replace />;
};

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/login" element={<PayrollLogin />} />
          <Route path="/pensions" element={<PensionsOverview />} />
          <Route path="/landingPage" element={<LandingPage />} />
          <Route path="/filings/employer" element={<FilingsGrid />} />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employee-management"
            element={
              <ProtectedRoute>
                <EmployeeManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employer-management"
            element={
              <ProtectedRoute>
                <EmployerManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/earnings"
            element={
              <ProtectedRoute>
                <Earnings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employee/:employeeId"
            element={
              <ProtectedRoute>
                <EmployeeOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/time-off/policy/:id"
            element={
              <ProtectedRoute>
                <PolicyConfig />
              </ProtectedRoute>
            }
          />
          <Route path="/time-off" element={<TimeOff />} />
          <Route path="/run-payroll/:payrunId" element={<RunPayroll />} />
          <Route
            path="/payroll-approval/:payrunId"
            element={<PayrollApproval />}
          />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
        </Routes>
        <Toaster />
      </Router>
    </ThemeProvider>
  );
};

export default App;
