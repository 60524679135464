import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Drawer,
  Typography,
  IconButton,
  Chip,
  Card,
  CardHeader,
  CardContent,
  Button,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import {
  ChevronRight,
  Download as DownloadIcon,
  Article as ArticleIcon,
} from "@mui/icons-material";
import { format } from "date-fns";
import ApiService from "@/services/api";
import { EndpointKeys } from "@/config/api";
import MenuBar from "@/components/MenuBar";

interface FilingDocument {
  id: number;
  documentType: string;
  hasContents: boolean;
}

interface FilingVersion {
  id: number;
  version: number;
  fulfillmentDate: string;
  status: string;
  documents: FilingDocument[];
}

interface Filing {
  id: number;
  coveragePeriodStart: string;
  coveragePeriodEnd: string;
  filingType: string;
  latestVersion: FilingVersion;
  employerName: string;
  employerHMRCRef: string;
  allVersions: FilingVersion[];
}

interface FilingResponse {
  filings: Filing[];
  totalCount: number;
}

interface User {
  id: number;
  email: string;
}

export default function FilingsGrid() {
  const [filings, setFilings] = useState<Filing[]>([]);
  const [selectedFiling, setSelectedFiling] = useState<Filing | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [isSuperUser, setIsSuperUser] = useState(false);

  useEffect(() => {
    checkSuperUserStatus();
  }, []);

  useEffect(() => {
    fetchFilings();
  }, [selectedUserId]);

  const checkSuperUserStatus = async () => {
    try {
      const response = await ApiService.request<{ users: User[] }>(
        EndpointKeys.FETCH_ALL_USERS,
        {},
      );
      setUsers(response.users);
      setIsSuperUser(true);
    } catch (error) {
      setIsSuperUser(false);
      console.error("Not a superuser or error fetching users:", error);
    }
  };

  const fetchFilings = async () => {
    try {
      const response = await ApiService.request<FilingResponse>(
        EndpointKeys.FETCH_EMPLOYER_FILINGS,
        {
          queryParams: selectedUserId ? { userId: selectedUserId } : undefined,
        },
      );
      setFilings(response.filings);
    } catch (error) {
      console.error("Error fetching filings:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserChange = (event: SelectChangeEvent<string>) => {
    setSelectedUserId(event.target.value);
  };

  const getStatusColor = (
    status: string,
  ): "warning" | "info" | "success" | "error" | "default" => {
    switch (status.toLowerCase()) {
      case "pending":
        return "warning";
      case "sent":
        return "info";
      case "success":
        return "success";
      case "failed":
        return "error";
      default:
        return "default";
    }
  };

  const handleRowClick = (filing: Filing) => {
    setSelectedFiling(filing);
    setIsDrawerOpen(true);
  };

  const handleDownloadDocument = async (documentId: number) => {
    try {
      const blob = await ApiService.downloadFile(
        EndpointKeys.FETCH_EMPLOYER_FILING_DOCUMENT,
        {
          urlParams: { id: documentId },
        },
      );

      // Get the filename from the response headers if available
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `filing-document-${documentId}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading document:", error);
    }
  };

  const DrawerContent = ({ filing }: { filing: Filing }) => (
    <Box sx={{ width: 400, p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Filing Details
      </Typography>

      <Card sx={{ mb: 2 }}>
        <CardHeader title="Filing Information" />
        <CardContent>
          <Typography variant="body2" gutterBottom>
            <strong>Coverage Period:</strong>
            <br />
            {format(new Date(filing.coveragePeriodStart), "PP")} -{" "}
            {format(new Date(filing.coveragePeriodEnd), "PP")}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Employer:</strong>
            <br />
            {filing.employerName} ({filing.employerHMRCRef})
          </Typography>
          <Typography variant="body2">
            <strong>Filing Type:</strong> {filing.filingType}
          </Typography>
        </CardContent>
      </Card>

      <Card>
        <CardHeader title="Version History" />
        <CardContent>
          {filing.allVersions.map((version) => (
            <Box
              key={version.id}
              sx={{
                mb: 2,
                p: 2,
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="subtitle2">
                    Version {version.version}
                  </Typography>
                  <Chip
                    label={version.status}
                    color={getStatusColor(version.status)}
                    size="small"
                    sx={{ ml: 1 }}
                  />
                </Box>
                <Typography variant="caption" color="text.secondary">
                  {format(new Date(version.fulfillmentDate), "PP")}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                {version.documents.map((doc) => (
                  <Button
                    key={doc.id}
                    variant="outlined"
                    size="small"
                    onClick={() => handleDownloadDocument(doc.id)}
                    disabled={!doc.hasContents}
                    startIcon={<DownloadIcon />}
                  >
                    {doc.documentType}
                  </Button>
                ))}
              </Box>
            </Box>
          ))}
        </CardContent>
      </Card>
    </Box>
  );

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <MenuBar />
      <div className="flex-1 overflow-auto">
        <Box sx={{ m: 3 }}>
          {isSuperUser && (
            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel>Select User</InputLabel>
              <Select
                value={selectedUserId}
                label="Select User"
                onChange={handleUserChange}
              >
                <MenuItem value="">
                  <em>Current User</em>
                </MenuItem>
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id.toString()}>
                    {user.email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {isSuperUser && <TableCell>Email</TableCell>}
                    <TableCell>Coverage Period</TableCell>
                    <TableCell>Filing Type</TableCell>
                    <TableCell>Employer</TableCell>
                    <TableCell>HMRC Reference</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Documents</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filings.map((filing) => (
                    <TableRow
                      key={filing.id}
                      hover
                      onClick={() => handleRowClick(filing)}
                      sx={{ cursor: "pointer" }}
                    >
                      {isSuperUser && (
                        <TableCell>
                          {
                            users.find(
                              (value: User) =>
                                value.id === parseInt(selectedUserId),
                            )?.email
                          }
                        </TableCell>
                      )}
                      <TableCell>
                        {format(new Date(filing.coveragePeriodStart), "PP")} -{" "}
                        {format(new Date(filing.coveragePeriodEnd), "PP")}
                      </TableCell>
                      <TableCell>{filing.filingType}</TableCell>
                      <TableCell>{filing.employerName}</TableCell>
                      <TableCell>{filing.employerHMRCRef}</TableCell>
                      <TableCell>
                        <Chip
                          label={filing.latestVersion.status}
                          color={getStatusColor(filing.latestVersion.status)}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          {filing.latestVersion.documents.map((doc) => (
                            <ArticleIcon
                              key={doc.id}
                              color={doc.hasContents ? "action" : "disabled"}
                              fontSize="small"
                            />
                          ))}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <IconButton size="small">
                          <ChevronRight />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
          >
            {selectedFiling && <DrawerContent filing={selectedFiling} />}
          </Drawer>
        </Box>
      </div>
    </div>
  );
}
