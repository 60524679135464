import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import MenuBar from "@/components/MenuBar";

interface EmployerData {
  id?: number;
  payeReferenceNumber: string;
  accountsOfficeNumber: string;
  gatewayCredentialsNumber: string;
  gatewayCredentialsPassword: string;
  payeGatewayAuthorizationCode: string;
}

const EmployerManagement: React.FC = () => {
  const [employer, setEmployer] = useState<EmployerData | null>(null);
  const [editingEmployer, setEditingEmployer] = useState<EmployerData>({
    payeReferenceNumber: "",
    accountsOfficeNumber: "",
    gatewayCredentialsNumber: "",
    gatewayCredentialsPassword: "",
    payeGatewayAuthorizationCode: "",
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [payeReferenceError, setPayeReferenceError] = useState<string | null>(
    null,
  );
  const [accountsOfficeError, setAccountsOfficeError] = useState<string | null>(
    null,
  );

  useEffect(() => {
    fetchEmployer();
  }, []);

  const getAuthToken = () => {
    return localStorage.getItem("authToken");
  };

  const fetchEmployer = async () => {
    try {
      const token = getAuthToken();
      if (!token) {
        throw new Error("No auth token found");
      }

      const response = await fetch(
        `http://localhost:8000/employees/get_uk_employer/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error("Unauthorized: Please log in again");
        }
        throw new Error("Failed to fetch employer");
      }

      const data: EmployerData = await response.json();
      setEmployer(data);
    } catch (error) {
      console.error("Error fetching employer:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditingEmployer({ ...editingEmployer, [name]: value });
    if (name === "payeReferenceNumber") {
      validatePayeReferenceNumber(value);
    } else if (name === "accountsOfficeNumber") {
      validateAccountsOfficeNumber(value);
    }
  };

  const validatePayeReferenceNumber = (value: string) => {
    const regex = /^\d{3}\/[a-zA-Z0-9]{1,10}$/;
    if (!regex.test(value)) {
      setPayeReferenceError(
        "Invalid format. Should be 3 numbers, a forward slash, and 1-10 alphanumeric characters.",
      );
    } else {
      setPayeReferenceError(null);
    }
  };

  const validateAccountsOfficeNumber = (value: string) => {
    const regex = /^\d{3}P[A-Z](\d{8}|\d{7}X)$/;
    if (!regex.test(value)) {
      setAccountsOfficeError(
        "Invalid format. Should be 3 numbers, 'P', a letter, and 8 numbers (or 7 numbers and 'X').",
      );
    } else {
      setAccountsOfficeError(null);
    }
  };

  const handleEditClick = () => {
    if (employer) {
      setEditingEmployer({
        ...employer,
        gatewayCredentialsPassword: "", // Clear password for security
      });
    }
    setIsDialogOpen(true);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (payeReferenceError || accountsOfficeError) {
      alert("Please correct the errors before submitting.");
      return;
    }
    try {
      const token = getAuthToken();
      if (!token) {
        throw new Error("No auth token found");
      }

      const url = "http://localhost:8000/employees/create_uk_employer/";
      const method = "POST";

      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(editingEmployer),
      });

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error("Unauthorized: Please log in again");
        }
        throw new Error("Failed to save employer");
      }

      fetchEmployer(); // Refresh the employer data
      setIsDialogOpen(false); // Close the dialog
    } catch (error) {
      console.error("Error saving employer:", error);
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <MenuBar />
      <div className="flex-1 overflow-auto">
        <Card>
          <CardHeader>
            <h1 className="text-2xl font-bold">Employer Management</h1>
          </CardHeader>
          <CardContent>
            <div className="mb-4">
              <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogTrigger asChild>
                  <Button onClick={handleEditClick}>
                    {employer ? "Edit Employer" : "Create Employer"}
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>
                      {employer ? "Edit Employer" : "Create Employer"}
                    </DialogTitle>
                  </DialogHeader>
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                      <Label htmlFor="payeReferenceNumber">
                        PAYE Reference Number
                      </Label>
                      <Input
                        id="payeReferenceNumber"
                        name="payeReferenceNumber"
                        value={editingEmployer.payeReferenceNumber}
                        onChange={handleInputChange}
                        required
                      />
                      {payeReferenceError && (
                        <p className="text-red-500 text-sm mt-1">
                          {payeReferenceError}
                        </p>
                      )}
                    </div>
                    <div>
                      <Label htmlFor="accountsOfficeNumber">
                        Accounts Office Number
                      </Label>
                      <Input
                        id="accountsOfficeNumber"
                        name="accountsOfficeNumber"
                        value={editingEmployer.accountsOfficeNumber}
                        onChange={handleInputChange}
                        required
                      />
                      {accountsOfficeError && (
                        <p className="text-red-500 text-sm mt-1">
                          {accountsOfficeError}
                        </p>
                      )}
                    </div>
                    <div>
                      <Label htmlFor="gatewayCredentialsNumber">
                        Gateway Credentials Number
                      </Label>
                      <Input
                        id="gatewayCredentialsNumber"
                        name="gatewayCredentialsNumber"
                        value={editingEmployer.gatewayCredentialsNumber}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div>
                      <Label htmlFor="gatewayCredentialsPassword">
                        Gateway Credentials Password
                      </Label>
                      <Input
                        id="gatewayCredentialsPassword"
                        name="gatewayCredentialsPassword"
                        type="password"
                        value={editingEmployer.gatewayCredentialsPassword}
                        onChange={handleInputChange}
                        required={!employer} // Only required for new employers
                        placeholder={
                          employer ? "Leave blank to keep current password" : ""
                        }
                      />
                    </div>
                    <div>
                      <Label htmlFor="payeGatewayAuthorizationCode">
                        PAYE Gateway Authorization Code
                      </Label>
                      <Input
                        id="payeGatewayAuthorizationCode"
                        name="payeGatewayAuthorizationCode"
                        value={editingEmployer.payeGatewayAuthorizationCode}
                        onChange={handleInputChange}
                      />
                    </div>
                    <Button
                      type="submit"
                      disabled={!!payeReferenceError || !!accountsOfficeError}
                    >
                      Save Employer
                    </Button>
                  </form>
                </DialogContent>
              </Dialog>
            </div>
            {employer ? (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>PAYE Reference Number</TableHead>
                    <TableHead>Accounts Office Number</TableHead>
                    <TableHead>Gateway Credentials Number</TableHead>
                    <TableHead>PAYE Gateway Authorization Code</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell>{employer.payeReferenceNumber}</TableCell>
                    <TableCell>{employer.accountsOfficeNumber}</TableCell>
                    <TableCell>{employer.gatewayCredentialsNumber}</TableCell>
                    <TableCell>
                      {employer.payeGatewayAuthorizationCode}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ) : (
              <p>No employer data available. Please create an employer.</p>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default EmployerManagement;
