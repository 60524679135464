import React, { useState } from "react";
import {
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import { Check, Close, Delete } from "@mui/icons-material";

interface ModalEditableCellProps {
  initialValue: string;
  isDisabled?: boolean;
  onSave: (value: string) => void;
  onRemove: () => Promise<string>;
}

const ModalEditableCell: React.FC<ModalEditableCellProps> = ({
  initialValue,
  isDisabled = false,
  onSave,
  onRemove,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(initialValue);

  const handleOpen = () => {
    if (!isDisabled) {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setValue(initialValue);
  };

  const handleSave = () => {
    onSave(value);
    setIsOpen(false);
  };

  const handleRemove = async () => {
    const defaultValue = await onRemove();
    setValue(defaultValue);
    setIsOpen(false);
  };

  return (
    <>
      <TableCell
        onClick={handleOpen}
        style={{ cursor: isDisabled ? "default" : "pointer" }}
      >
        {value}
      </TableCell>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Edit Value</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <IconButton onClick={handleRemove} color="error">
            <Delete />
          </IconButton>
          <IconButton onClick={handleClose} color="primary">
            <Close />
          </IconButton>
          <IconButton onClick={handleSave} color="primary">
            <Check />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalEditableCell;
