import React from "react";
import { Box } from "@mui/material";
import AppAppBar from "./landing_page/AppAppBar";
import Hero from "./landing_page/Hero";
import Features from "./landing_page/Features";
import Highlights from "./landing_page/Highlights";
import Footer from "./landing_page/Footer";

const LandingPage = () => {
  return (
    <Box sx={{ bgcolor: "background.default", color: "text.primary" }}>
      <AppAppBar />
      <Hero />
      <Features />
      <Highlights />
      <Footer />
    </Box>
  );
};

export default LandingPage;
