import React, { useState, useEffect } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Label } from "@/components/ui/label";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from "@/components/ui/tooltip";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Info, HelpCircle } from "lucide-react";
import { format } from "date-fns";
import { EndpointKeys } from "@/config/api";
import ApiService from "@/services/api";

interface AggregatedAmount {
  currentAmount: string;
  wtd: string;
  mtd: string;
  ytd: string;
}

interface AggregatedHours {
  currentAmount: string;
  wtd: string;
  mtd: string;
  ytd: string;
  currentRate: string | null;
}

interface AggregatedEarningsOutput {
  earningCode: string;
  aggregatedAmount: AggregatedAmount;
  aggregatedHours: AggregatedHours;
}

interface AggregatedDeductionOutput {
  deductionCode: string;
  employeeContributionAmount: AggregatedAmount;
  employerContributionAmount: AggregatedAmount;
}

interface SubjectWageThreshold {
  thresholdName: string;
  aggregatedAmount: AggregatedAmount;
}

interface AggregatedTaxOutput {
  taxCode: string;
  aggregatedAmount: AggregatedAmount;
  subjectWagesByThreshold: SubjectWageThreshold[];
}

interface AggregatedData {
  earningsOutputByEarningCode: Record<string, AggregatedEarningsOutput>;
  deductionsOutputByDeductionCode: Record<string, AggregatedDeductionOutput>;
  taxOutputByTaxCode: Record<string, AggregatedTaxOutput>;
}

interface HistoricalImport {
  id: number;
  fiscalYearStart: string;
  fiscalYearEnd: string;
  importDate: string;
  description: string;
  aggregatedData: AggregatedData;
}

interface NIThresholds {
  LEL: string;
  StToPt: string;
  FustToUEL: string;
  AboveUEL: string;
}

// Field Help Text
const fieldHelp = {
  earnings_ytd:
    "Total earnings paid through previous provider this tax year, before any deductions",
  income_tax_ytd:
    "Total income tax deducted through previous provider this tax year",
  ni_letter:
    "National Insurance category letter from previous payslips, typically 'A' for most employees",
  employee_ni_ytd:
    "Total employee National Insurance contributions deducted this tax year",
  employer_ni_ytd:
    "Total employer National Insurance contributions paid this tax year",
  ni_thresholds: {
    LEL: "Earnings at the Lower Earnings Limit (LEL)",
    StToPt: "Earnings above LEL up to and including Secondary Threshold (ST)",
    FustToUEL:
      "Earnings above ST up to and including Upper Earnings Limit (UEL)",
    AboveUEL: "Earnings above the Upper Earnings Limit",
  },
};

// Location Hints
const p60LocationHints = {
  earnings_ytd:
    "Box 'Pay in this employment' under 'Pay and Income Tax details'",
  income_tax_ytd:
    "Box 'Tax deducted in this employment' under 'Pay and Income Tax details'",
  ni_letter:
    "Column 'NIC letter' under 'National Insurance contributions in this employment'",
  employee_ni_ytd:
    "Box 'Employee's contributions due on all earnings above the PT' under NI contributions",
  employer_ni_ytd: "Total found in employer's copy of P60 or final payslip",
  ni_thresholds: {
    LEL: "Column 'Earnings at LEL' in NI contributions table",
    StToPt: "Column 'Earnings above LEL up to PT' in NI contributions table",
    FustToUEL: "Column 'Earnings above PT up to UEL' in NI contributions table",
    AboveUEL: "Calculate: Total earnings minus sum of other thresholds",
  },
};

const importSchema = z.object({
  tax_year: z.string().regex(/^\d{4}$/, "Must be a valid year"),
  earnings_ytd: z
    .string()
    .regex(/^\d+(\.\d{2})?$/, "Must be a valid amount with 2 decimal places")
    .refine((val) => parseFloat(val) >= 0, "Must be a positive number"),
  income_tax_ytd: z
    .string()
    .regex(/^\d+(\.\d{2})?$/, "Must be a valid amount with 2 decimal places")
    .refine((val) => parseFloat(val) >= 0, "Must be a positive number"),
  ni_letter: z
    .string()
    .regex(/^[A-Z]$/, "Must be a single capital letter")
    .refine(
      (val) => ["A", "B", "C", "H", "J", "M", "Z"].includes(val),
      "Must be a valid NI letter",
    ),
  employee_ni_ytd: z
    .string()
    .regex(/^\d+(\.\d{2})?$/, "Must be a valid amount with 2 decimal places")
    .refine((val) => parseFloat(val) >= 0, "Must be a positive number"),
  employer_ni_ytd: z
    .string()
    .regex(/^\d+(\.\d{2})?$/, "Must be a valid amount with 2 decimal places")
    .refine((val) => parseFloat(val) >= 0, "Must be a positive number"),
  ni_thresholds: z.object({
    LEL: z
      .string()
      .regex(/^\d+(\.\d{2})?$/, "Must be a valid amount with 2 decimal places"),
    StToPt: z
      .string()
      .regex(/^\d+(\.\d{2})?$/, "Must be a valid amount with 2 decimal places"),
    FustToUEL: z
      .string()
      .regex(/^\d+(\.\d{2})?$/, "Must be a valid amount with 2 decimal places"),
    AboveUEL: z
      .string()
      .regex(/^\d+(\.\d{2})?$/, "Must be a valid amount with 2 decimal places"),
  }),
});

type FormValues = z.infer<typeof importSchema>;

interface ImportInformationProps {
  employeeId: string;
}

interface ImportVersion {
  id: number;
  historicalImportId: number;
  changeEventId: number;
  recordedTime: Date;
  isActive: boolean;
}

interface ImportOutput {
  id: number;
  importVersionId: number;
  aggregatedData: Record<string, any>;
}

interface ImportVersionWithOutput {
  version: ImportVersion;
  output?: ImportOutput;
}

interface SubjectWageThreshold {
  thresholdName: string;
  aggregatedAmount: AggregatedAmount;
}

interface HistoricalImportsResponse {
  imports: HistoricalImport[];
  latestVersions: ImportVersionWithOutput[];
}

export const ImportInformation: React.FC<ImportInformationProps> = ({
  employeeId,
}) => {
  const [hasPreviousProvider, setHasPreviousProvider] = useState(false);
  const [existingImports, setExistingImports] = useState<HistoricalImport[]>(
    [],
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [latestVersions, setLatestVersions] = useState<
    ImportVersionWithOutput[]
  >([]);
  const [error, setError] = useState<string | null>(null);

  const form = useForm<FormValues>({
    resolver: zodResolver(importSchema),
    defaultValues: {
      tax_year: new Date().getFullYear().toString(),
      earnings_ytd: "",
      income_tax_ytd: "",
      ni_letter: "A",
      employee_ni_ytd: "",
      employer_ni_ytd: "",
      ni_thresholds: {
        LEL: "",
        StToPt: "",
        FustToUEL: "",
        AboveUEL: "",
      },
    },
  });

  useEffect(() => {
    if (employeeId) {
      fetchExistingImports();
    }
  }, [employeeId]);

  const fetchExistingImports = async () => {
    try {
      const data = await ApiService.request<HistoricalImportsResponse>(
        EndpointKeys.FETCH_IMPORTS,
        {
          urlParams: { id: employeeId },
        },
      );

      setLatestVersions(data.latestVersions);
      // Get the latest version with output
      if (!data.latestVersions) {
        return;
      }
      const latestVersion = data.latestVersions[0];
      if (latestVersion && latestVersion.output) {
        setHasPreviousProvider(true);
        setExistingImports([data.imports[0]]); // Set the single historical import

        // Map the version's output data to form fields
        const aggregatedData = latestVersion.output.aggregatedData;
        const taxOutput = aggregatedData.taxOutputByTaxCode;
        const earningsOutput = aggregatedData.earningsOutputByEarningCode;

        form.reset({
          tax_year: new Date(data.imports[0].fiscalYearEnd)
            .getFullYear()
            .toString(),
          earnings_ytd: earningsOutput.salary?.aggregatedAmount.ytd || "0.00",
          income_tax_ytd: taxOutput.income_tax?.aggregatedAmount.ytd || "0.00",
          ni_letter: "A",
          employee_ni_ytd:
            taxOutput.employee_nic?.aggregatedAmount.ytd || "0.00",
          employer_ni_ytd:
            taxOutput.employer_nic?.aggregatedAmount.ytd || "0.00",
          ni_thresholds: {
            LEL:
              taxOutput.employee_nic?.subjectWagesByThreshold.find(
                (t: SubjectWageThreshold) => t.thresholdName === "LEL",
              )?.aggregatedAmount.ytd || "0.00",
            StToPt:
              taxOutput.employee_nic?.subjectWagesByThreshold.find(
                (t: SubjectWageThreshold) => t.thresholdName === "StToPt",
              )?.aggregatedAmount.ytd || "0.00",
            FustToUEL:
              taxOutput.employee_nic?.subjectWagesByThreshold.find(
                (t: SubjectWageThreshold) => t.thresholdName === "FustToUEL",
              )?.aggregatedAmount.ytd || "0.00",
            AboveUEL:
              taxOutput.employee_nic?.subjectWagesByThreshold.find(
                (t: SubjectWageThreshold) => t.thresholdName === "AboveUEL",
              )?.aggregatedAmount.ytd || "0.00",
          },
        });
      }
    } catch (error) {
      console.error("Error fetching imports:", error);
      setError("Failed to fetch existing imports");
    }
  };

  const handleProviderToggle = async (checked: boolean) => {
    setHasPreviousProvider(checked);
    if (!checked && existingImports.length > 0) {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) throw new Error("No auth token found");

        const latestImport = existingImports[existingImports.length - 1];
        await fetch(
          `http://localhost:8000/employees/${employeeId}/imports/${latestImport.id}/revise/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              reason: "Provider status changed to no previous provider",
              ...latestImport.aggregatedData,
              isActive: false,
            }),
          },
        );

        await fetchExistingImports();
      } catch (error) {
        console.error("Error deactivating import:", error);
        setError("Failed to update provider status");
      }
    }
  };

  const onSubmit = async (data: FormValues) => {
    setIsSubmitting(true);
    setError(null);
    try {
      const endpoint =
        existingImports.length > 0
          ? EndpointKeys.UPDATE_P60_IMPORTS
          : EndpointKeys.CREATE_P60_IMPORTS;

      const urlParams: Record<string, string> =
        existingImports.length > 0
          ? {
              employeeId: employeeId.toString(),
              importId:
                existingImports[existingImports.length - 1].id.toString(),
            }
          : { id: employeeId.toString() };

      await ApiService.request(endpoint, {
        method: "POST",
        urlParams: urlParams,
        body:
          existingImports.length > 0
            ? {
                ...data,
                reason: "Updated P60 information",
              }
            : data,
      });

      await fetchExistingImports();
    } catch (error) {
      console.error("Error submitting import:", error);
      setError("Failed to submit import data");
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderFormField = (
    name: keyof Omit<FormValues, "ni_thresholds">, // Exclude ni_thresholds from regular fields
    label: string,
    tooltipText: string,
    locationHint: string,
  ) => (
    <FormField
      control={form.control}
      name={name}
      render={({ field: { onChange, value, ...fieldProps } }) => (
        <FormItem>
          <div className="flex items-center space-x-2">
            <FormLabel>{label}</FormLabel>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <HelpCircle className="h-4 w-4 text-muted-foreground" />
                </TooltipTrigger>
                <TooltipContent>
                  <p>{tooltipText}</p>
                  <p className="mt-2 text-sm text-muted">
                    Location on P60: {locationHint}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <FormControl>
            <Input
              type="text"
              value={value as string}
              onChange={onChange}
              {...fieldProps}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );

  const renderNIThresholdField = (
    key: keyof NIThresholds,
    label: string,
    tooltipText: string,
    locationHint: string,
  ) => (
    <FormField
      control={form.control}
      name={`ni_thresholds.${key}` as const}
      render={({ field: { onChange, value, ...fieldProps } }) => (
        <FormItem>
          <div className="flex items-center space-x-2">
            <FormLabel>{label}</FormLabel>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <HelpCircle className="h-4 w-4 text-muted-foreground" />
                </TooltipTrigger>
                <TooltipContent>
                  <p>{tooltipText}</p>
                  <p className="mt-2 text-sm text-muted">
                    Location on P60: {locationHint}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <FormControl>
            <Input
              type="text"
              value={value as string}
              onChange={onChange}
              {...fieldProps}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );

  return (
    <Card className="w-full">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <div className="space-y-1">
          <h3 className="text-2xl font-semibold leading-none tracking-tight">
            Previous Payroll Data
          </h3>
          <p className="text-sm text-muted-foreground">
            Import historical payroll data from previous providers
          </p>
        </div>
      </CardHeader>
      <CardContent>
        <Alert className="mb-6">
          <AlertDescription>
            <div className="space-y-2">
              <p>
                The UK tax year runs from 6th April to 5th April. For accurate
                tax calculations, we need details of any payments made through
                previous providers this tax year.
              </p>
              <p>
                You'll need your nanny's P60 or final payslip from the previous
                provider to complete this section.
              </p>
            </div>
          </AlertDescription>
        </Alert>

        <div className="flex items-center space-x-2 mb-6">
          <Switch
            checked={hasPreviousProvider}
            onCheckedChange={handleProviderToggle}
          />
          <Label>
            Have you paid your nanny through another provider this tax year?
          </Label>
        </div>

        {error && (
          <Alert variant="destructive" className="mb-6">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {hasPreviousProvider && (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              {renderFormField(
                "earnings_ytd",
                "Total Earnings YTD",
                fieldHelp.earnings_ytd,
                p60LocationHints.earnings_ytd,
              )}

              {renderFormField(
                "income_tax_ytd",
                "Income Tax YTD",
                fieldHelp.income_tax_ytd,
                p60LocationHints.income_tax_ytd,
              )}

              <FormField
                control={form.control}
                name="ni_letter"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex items-center space-x-2">
                      <FormLabel>NI Letter</FormLabel>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger>
                            <HelpCircle className="h-4 w-4 text-muted-foreground" />
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{fieldHelp.ni_letter}</p>
                            <p className="mt-2 text-sm text-muted">
                              Location on P60: {p60LocationHints.ni_letter}
                            </p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select NI category" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="A">A - Standard rate</SelectItem>
                        <SelectItem value="B">
                          B - Married women's reduced rate
                        </SelectItem>
                        <SelectItem value="C">C - Pension age</SelectItem>
                        <SelectItem value="H">
                          H - Apprentice under 25
                        </SelectItem>
                        <SelectItem value="J">J - Deferred rate</SelectItem>
                        <SelectItem value="M">M - Under 21</SelectItem>
                        <SelectItem value="Z">Z - No NICs due</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {renderFormField(
                "employee_ni_ytd",
                "Employee NI YTD",
                fieldHelp.employee_ni_ytd,
                p60LocationHints.employee_ni_ytd,
              )}

              {renderFormField(
                "employer_ni_ytd",
                "Employer NI YTD",
                fieldHelp.employer_ni_ytd,
                p60LocationHints.employer_ni_ytd,
              )}

              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <h4 className="text-sm font-medium">NI Thresholds</h4>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Info className="h-4 w-4 text-muted-foreground" />
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>
                          National Insurance contribution thresholds from P60
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {renderNIThresholdField(
                    "LEL",
                    "Lower Earnings Limit (LEL)",
                    fieldHelp.ni_thresholds.LEL,
                    p60LocationHints.ni_thresholds.LEL,
                  )}

                  {renderNIThresholdField(
                    "StToPt",
                    "Secondary Threshold (ST)",
                    fieldHelp.ni_thresholds.StToPt,
                    p60LocationHints.ni_thresholds.StToPt,
                  )}

                  {renderNIThresholdField(
                    "FustToUEL",
                    "Upper Secondary Threshold (FUST)",
                    fieldHelp.ni_thresholds.FustToUEL,
                    p60LocationHints.ni_thresholds.FustToUEL,
                  )}

                  {renderNIThresholdField(
                    "AboveUEL",
                    "Above Upper Earnings Limit",
                    fieldHelp.ni_thresholds.AboveUEL,
                    p60LocationHints.ni_thresholds.AboveUEL,
                  )}
                </div>
              </div>

              <div className="flex justify-end space-x-4">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => form.reset()}
                  disabled={isSubmitting}
                >
                  Reset
                </Button>
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Submitting..." : "Submit Import"}
                </Button>
              </div>
            </form>
          </Form>
        )}
        {existingImports.length > 0 && (
          <div className="mt-8">
            <h4 className="text-lg font-semibold mb-4">Import History</h4>
            <Table>
              <TableCaption>Previous payroll data imports</TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead>Tax Year</TableHead>
                  <TableHead>Import Date</TableHead>
                  <TableHead>Description</TableHead>
                  <TableHead>Earnings YTD</TableHead>
                  <TableHead>Tax YTD</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {existingImports.map((importData) => {
                  // Get all versions for this import
                  const importVersions = latestVersions.filter(
                    (v: ImportVersionWithOutput) =>
                      v.version.historicalImportId === importData.id,
                  );

                  // Return a row for each version of this import
                  return importVersions.map(
                    (version: ImportVersionWithOutput) => {
                      const outputData = version.output?.aggregatedData;

                      return (
                        <TableRow key={version.version.id}>
                          <TableCell>
                            {format(
                              new Date(importData.fiscalYearStart),
                              "yyyy",
                            )}
                            /{format(new Date(importData.fiscalYearEnd), "yy")}
                          </TableCell>
                          <TableCell>
                            {format(
                              new Date(version.version.recordedTime),
                              "PP",
                            )}
                          </TableCell>
                          <TableCell>
                            {importData.description}
                            {version.version.isActive && " (Active)"}
                          </TableCell>
                          <TableCell>
                            £
                            {outputData?.earningsOutputByEarningCode.salary
                              ?.aggregatedAmount.ytd || "0.00"}
                          </TableCell>
                          <TableCell>
                            £
                            {outputData?.taxOutputByTaxCode.income_tax
                              ?.aggregatedAmount.ytd || "0.00"}
                          </TableCell>
                        </TableRow>
                      );
                    },
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default ImportInformation;
