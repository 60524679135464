import React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  Stack,
} from "@mui/material";

const Hero: React.FC = () => {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "dark"
            ? "radial-gradient(ellipse 80% 50% at 50% -20%, rgba(120, 119, 198, 0.3), transparent)"
            : "radial-gradient(ellipse 80% 50% at 50% -20%, rgba(120, 119, 198, 0.1), transparent)",
        pt: { xs: 12, sm: 22 }, // Increased top padding
        pb: { xs: 8, sm: 12 },
      })}
    >
      <Container maxWidth="lg">
        <Stack spacing={4} alignItems="center">
          <Typography
            variant="h1"
            align="center"
            sx={{
              fontSize: { xs: "2.5rem", sm: "3.5rem", md: "4.5rem" },
              fontWeight: "bold",
              mb: 2,
              mt: { xs: 4, sm: 6 }, // Added top margin
            }}
          >
            Payroll for <span style={{ color: "#3399FF" }}>Families</span>
          </Typography>
          <Typography
            variant="h5"
            align="center"
            sx={{ mb: 4, maxWidth: "800px", textAlign: "center" }}
          >
            Streamline your nanny payroll with our all-in-one solution. From
            hiring to taxes and filings, we handle it all so you can focus on
            what matters most - your family.
          </Typography>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
              width: "100%",
              maxWidth: "400px",
            }}
          >
            <TextField
              fullWidth
              placeholder="Your email address"
              variant="outlined"
              sx={{
                bgcolor: "background.paper",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "divider",
                  },
                  "&:hover fieldset": {
                    borderColor: "primary.main",
                  },
                },
              }}
            />
            <Button variant="contained" color="primary" size="large" fullWidth>
              Start now
            </Button>
          </Box>
          <Typography variant="caption" sx={{ mt: 2, color: "text.secondary" }}>
            By clicking "Start now" you agree to our Terms & Conditions.
          </Typography>
          <Box
            component="img"
            src="/dashboardPreview.png"
            alt="Dashboard Preview"
            sx={{
              width: "100%",
              maxWidth: "1000px",
              height: "auto",
              borderRadius: 2,
              boxShadow: (theme) =>
                theme.palette.mode === "dark"
                  ? "0 0 24px 12px rgba(0, 0, 0, 0.3)"
                  : "0 0 24px 12px rgba(0, 0, 0, 0.1)",
              mt: 4, // Added top margin to the image
            }}
          />
        </Stack>
      </Container>
    </Box>
  );
};

export default Hero;
