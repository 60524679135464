import React, { useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  TextField,
  Button,
  Alert,
  Box,
  Typography,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Email as EmailIcon, Lock as LockIcon } from "@mui/icons-material";
import ApiService from "@/services/api";
import { EndpointKeys } from "@/config/api";

const SignUp: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSignUp = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (password !== confirmPassword) {
      setError("Passwords don't match");
      setLoading(false);
      return;
    }

    try {
      const response = await ApiService.request<any>(
        EndpointKeys.SIGNUP,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            email: email,
            password1: password,
            password2: confirmPassword,
          },
        },
        false,
      );

      if (response && response.status !== 204) {
        throw new Error("Sign up failed");
      }

      // Handle successful sign up here, e.g., redirect to login page
      navigate("/login");
    } catch (err) {
      console.log(err);
      setError("Sign up failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        bgcolor: "background.default",
      }}
    >
      <Card sx={{ maxWidth: 400, width: "100%" }}>
        <CardHeader
          title="Sign Up for Payroll System"
          titleTypographyProps={{ align: "center", variant: "h5" }}
        />
        <CardContent>
          <form onSubmit={handleSignUp}>
            <TextField
              fullWidth
              type="email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              margin="normal"
              InputProps={{
                startAdornment: <EmailIcon color="action" />,
              }}
            />
            <TextField
              fullWidth
              type="password"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              margin="normal"
              InputProps={{
                startAdornment: <LockIcon color="action" />,
              }}
            />
            <TextField
              fullWidth
              type="password"
              label="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              margin="normal"
              InputProps={{
                startAdornment: <LockIcon color="action" />,
              }}
            />
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
            >
              {loading ? "Signing up..." : "Sign Up"}
            </LoadingButton>
          </form>
        </CardContent>
        <CardActions>
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ width: "100%" }}
          >
            Already have an account?{" "}
            <Link href="/login" underline="hover">
              Log in
            </Link>
          </Typography>
        </CardActions>
      </Card>
    </Box>
  );
};

export default SignUp;
