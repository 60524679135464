import React, { useState, useEffect } from "react";
import { addDays, format, isBefore } from "date-fns";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CalendarIcon } from "lucide-react";
import * as z from "zod";
import { cn } from "@/lib/utils";
import { Alert, AlertDescription } from "@/components/ui/alert";

const formSchema = z
  .object({
    effectiveDate: z.date({
      required_error: "Effective date is required",
    }),
    coverageStartDate: z.date({
      required_error: "Coverage start date is required",
    }),
  })
  .refine(
    (data) => {
      return !isBefore(data.effectiveDate, data.coverageStartDate);
    },
    {
      message: "Effective date cannot be before coverage start date",
      path: ["effectiveDate"],
    },
  );

interface CreateEnrollmentDialogProps {
  open: boolean;
  onClose: () => void;
  employeeId: number | null;
  employeeName: string;
  employeeStartDate?: Date | null;
  hasExistingEvent: boolean;
  onSubmit: (
    employeeId: number,
    effectiveDate: Date,
    coverageStartDate: Date,
  ) => Promise<void>;
}

export default function CreateEnrollmentDialog({
  open,
  onClose,
  employeeId,
  employeeName,
  employeeStartDate,
  hasExistingEvent,
  onSubmit,
}: CreateEnrollmentDialogProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      effectiveDate: new Date(),
      coverageStartDate: employeeStartDate || new Date(),
    },
  });

  useEffect(() => {
    if (open) {
      form.reset({
        effectiveDate: new Date(),
        coverageStartDate: employeeStartDate || new Date(),
      });
      setError(null);
    }
  }, [open, employeeStartDate, form]);

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    if (!employeeId) return;

    setIsSubmitting(true);
    setError(null);

    try {
      await onSubmit(
        employeeId,
        values.effectiveDate,
        values.coverageStartDate,
      );
      onClose();
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "Failed to create enrollment",
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const enrollmentEndDate = form.watch("effectiveDate")
    ? addDays(form.watch("effectiveDate"), 30)
    : null;

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Create New Enrollment Event</DialogTitle>
        </DialogHeader>

        <Alert>
          <AlertDescription>
            Creating an enrollment event for {employeeName}. The enrollment
            window will remain open for 30 days from the effective date.
          </AlertDescription>
        </Alert>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="space-y-6"
          >
            <FormField
              control={form.control}
              name="effectiveDate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Enrollment Window Start Date</FormLabel>
                  <FormControl>
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            format(field.value, "PPP")
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          disabled={(date) =>
                            date < new Date() ||
                            date < (employeeStartDate || new Date())
                          }
                        />
                      </PopoverContent>
                    </Popover>
                  </FormControl>
                  <FormDescription>
                    Enrollment window: {format(field.value, "PPP")} -{" "}
                    {enrollmentEndDate
                      ? format(enrollmentEndDate, "PPP")
                      : "N/A"}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            {!hasExistingEvent && (
              <FormField
                control={form.control}
                name="coverageStartDate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Coverage Start Date</FormLabel>
                    <FormControl>
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant="outline"
                            className={cn(
                              "w-full pl-3 text-left font-normal",
                              !field.value && "text-muted-foreground",
                            )}
                          >
                            {field.value ? (
                              format(field.value, "PPP")
                            ) : (
                              <span>Pick a date</span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            mode="single"
                            selected={field.value}
                            onSelect={field.onChange}
                            disabled={(date) =>
                              date < (employeeStartDate || new Date())
                            }
                          />
                        </PopoverContent>
                      </Popover>
                    </FormControl>
                    <FormDescription>
                      When benefits coverage will begin
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            {error && (
              <Alert variant="destructive">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            <DialogFooter>
              <Button type="button" variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting || !form.formState.isValid}
              >
                {isSubmitting ? "Creating..." : "Create Event"}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
