import React, { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { EmployeeTaxInfo } from "../types/employeeTypes";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { HelpCircle } from "lucide-react";
interface TaxInformationProps {
  taxInfo: EmployeeTaxInfo | null;
  setTaxInfo: React.Dispatch<React.SetStateAction<EmployeeTaxInfo | null>>;
  employeeId: string | undefined;
  toast: (props: {
    title: string;
    description: string;
    duration?: number;
  }) => void;
}

const TaxInformation: React.FC<TaxInformationProps> = ({
  taxInfo,
  setTaxInfo,
  employeeId,
  toast,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const starterDeclarationTooltips = {
    A: "Applies to new team members working their first job since April 6 of the current year who have not received a taxable state pension, occupational pension, incapacity benefit, jobseeker's allowance, or employment and support allowance.",
    B: "Applies to team members who work for only your business but have had another job since April 6 of the current year and don't have a P45. It also applies if they have received a taxable incapacity benefit, employment and support allowance, or job seekers allowance in the current tax year.",
    C: "Applies if the team member has another job and/or has received a private state or works pension.",
  };

  const validateTaxCode = (taxCode: string): boolean => {
    const taxCodeRegex =
      /([1-9][0-9]{0,5}[LMNPTY])|(BR)|(0T)|(NT)|(D[0-8])|([K][1-9][0-9]{0,5})/;
    return taxCodeRegex.test(taxCode);
  };

  const validateNino = (nino: string): boolean => {
    const ninoRegex =
      /^([ACEHJLMOPRSWXY][A-CEGHJ-NPR-TW-Z]|B[A-CEHJ-NPR-TW-Z]|G[ACEGHJ-NPR-TW-Z]|[KT][A-CEGHJ-MPR-TW-Z]|N[A-CEGHJL-NPR-SW-Z]|Z[A-CEGHJ-NPR-TW-Y])[0-9]{6}[A-D]$/;
    return ninoRegex.test(nino);
  };

  const validateField = (name: string, value: string) => {
    let error = "";
    switch (name) {
      case "taxCode":
        if (!validateTaxCode(value)) {
          error = "Invalid tax code format";
        }
        break;
      case "nationalInsuranceNumber":
        if (!validateNino(value)) {
          error =
            "Invalid national insurance number format. A National Insurance number (NI number) is formatted as two letters, six numbers, and a final letter";
        }
        break;
      // Add more cases for other fields as needed
    }
    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const handleTaxInfoChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: any } },
  ) => {
    const { name, value } = e.target;
    setTaxInfo((prev) => {
      if (!prev) return null;

      if (name === "hasHadPreviousJobInFiscalYear" && value === false) {
        return {
          ...prev,
          [name]: value,
          previousEmployerIncomeYTD: 0,
          previousEmployerIncomeTaxYTD: 0,
        };
      }

      if (name === "isCumulative") {
        return { ...prev, [name]: !value };
      }

      validateField(name, value);
      return { ...prev, [name]: value };
    });
  };

  const handleTaxInfoSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (Object.values(errors).some((error) => error !== "")) {
      toast({
        title: "Error",
        description: "Please correct all errors before submitting.",
        duration: 3000,
      });
      return;
    }
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No auth token found");
      }

      const response = await fetch(
        `http://localhost:8000/employees/${employeeId}/tax/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(taxInfo),
        },
      );

      if (!response.ok) {
        throw new Error(
          `Failed to ${taxInfo?.id ? "update" : "create"} tax information`,
        );
      }

      const updatedTaxInfo = await response.json();
      setTaxInfo(updatedTaxInfo);
      console.log("Tax information updated successfully");
      toast({
        title: "Success",
        description: `The tax information has been updated successfully`,
        duration: 3000,
      });
    } catch (error) {
      console.error("Error updating tax information:", error);
    }
  };

  if (!taxInfo) {
    return (
      <div>
        <p>
          No tax information available. Please fill out the form to create tax
          information.
        </p>
        <Button
          onClick={() =>
            setTaxInfo({
              id: null,
              nationalInsuranceNumber: "",
              nationalInsuranceLetter: "A",
              hasHadPreviousJobInFiscalYear: false,
              previousEmployerIncomeYTD: 0,
              previousEmployerIncomeTaxYTD: 0,
              starterDeclaration: null,
              taxRegime: "",
              taxCode: "",
              payId: "1",
              isCumulative: false,
              hasStudentLoan: false,
              studentLoanPlan: null,
              hasPostgradLoan: false,
            })
          }
        >
          Create Tax Information
        </Button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-2xl font-semibold mb-4">Tax Information</h2>
        <div className="bg-gray-100 p-4 rounded-md mb-6">
          <p className="text-sm text-gray-700">
            The information below should ideally come from the employee's P45 or
            directly from the employee. If the employee doesn't have this
            information, default values should be used. This tax information is
            crucial as it determines the employee's taxation. Accurate
            information ensures correct tax calculations and compliance with
            HMRC regulations.
          </p>
        </div>
        <form onSubmit={handleTaxInfoSubmit} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label
                htmlFor="nationalInsuranceNumber"
                className="flex items-center space-x-2"
              >
                <span>National Insurance Number</span>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <HelpCircle className="h-4 w-4 text-gray-500" />
                    </TooltipTrigger>
                    <TooltipContent className="max-w-md">
                      <p>
                        The National Insurance Number (NINO) is a unique
                        identifier for the UK tax system:
                      </p>
                      <ul className="list-disc pl-5 mt-2">
                        <li>It's in the format AA 12 34 56 A</li>
                        <li>
                          It's used to track your tax and National Insurance
                          contributions
                        </li>
                        <li>
                          You can find it on your payslip, P60, or official
                          letters about tax, pensions, or benefits
                        </li>
                      </ul>
                      <p className="mt-2">
                        If you don't have a NINO or can't find it, contact HMRC
                        or check the official gov.uk website for guidance.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </Label>
              <Input
                id="nationalInsuranceNumber"
                name="nationalInsuranceNumber"
                value={taxInfo?.nationalInsuranceNumber || ""}
                onChange={handleTaxInfoChange}
              />
              {errors.nationalInsuranceNumber && (
                <p className="text-red-500 text-sm">
                  {errors.nationalInsuranceNumber}
                </p>
              )}
            </div>

            <div className="space-y-2">
              <Label
                htmlFor="nationalInsuranceLetter"
                className="flex items-center space-x-2"
              >
                <span>National Insurance Letter</span>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <HelpCircle className="h-4 w-4 text-gray-500" />
                    </TooltipTrigger>
                    <TooltipContent className="max-w-md">
                      <p>
                        The National Insurance Letter determines the type and
                        amount of National Insurance contributions:
                      </p>
                      <ul className="list-disc pl-5 mt-2">
                        <li>A: Standard rate for most employees</li>
                        <li>
                          B: Married women and widows entitled to pay reduced
                          rate contributions
                        </li>
                        <li>C: Employees over the state pension age</li>
                        <li>
                          H, M: Specific rates for certain seafarers or
                          volunteers
                        </li>
                        <li>
                          J: Employees who can defer NI because they're already
                          paying the maximum in another job
                        </li>
                      </ul>
                      <p className="mt-2">
                        Your letter is typically determined by your employment
                        status and personal circumstances. If unsure, check your
                        latest payslip or contact HMRC.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </Label>
              <Select
                name="nationalInsuranceLetter"
                value={taxInfo?.nationalInsuranceLetter || "A"}
                onValueChange={(value) =>
                  handleTaxInfoChange({
                    target: { name: "nationalInsuranceLetter", value },
                  })
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select NI Letter" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="A">A</SelectItem>
                  <SelectItem value="B">B</SelectItem>
                  <SelectItem value="C">C</SelectItem>
                  <SelectItem value="H">H</SelectItem>
                  <SelectItem value="J">J</SelectItem>
                  <SelectItem value="M">M</SelectItem>
                  <SelectItem value="V">V</SelectItem>
                  <SelectItem value="Z">Z</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="col-span-2">
              <div className="flex items-center space-x-2">
                <Switch
                  id="hasHadPreviousJobInFiscalYear"
                  name="hasHadPreviousJobInFiscalYear"
                  checked={taxInfo?.hasHadPreviousJobInFiscalYear || false}
                  onCheckedChange={(checked: boolean) =>
                    handleTaxInfoChange({
                      target: {
                        name: "hasHadPreviousJobInFiscalYear",
                        value: checked,
                      },
                    })
                  }
                />
                <Label htmlFor="hasHadPreviousJobInFiscalYear">
                  Had Previous Job in Fiscal Year
                </Label>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <HelpCircle className="h-4 w-4 text-gray-500" />
                    </TooltipTrigger>
                    <TooltipContent className="max-w-md">
                      <p>
                        This refers to whether you've had another job since the
                        start of the current tax year (6th April):
                      </p>
                      <ul className="list-disc pl-5 mt-2">
                        <li>
                          If yes, your tax code and Personal Allowance may need
                          adjusting
                        </li>
                        <li>
                          You'll need to provide details of your previous
                          earnings and tax paid in this tax year
                        </li>
                        <li>
                          This ensures you're taxed correctly and don't overpay
                          or underpay tax
                        </li>
                      </ul>
                      <p className="mt-2">
                        If you're unsure, check your P45 from your previous
                        employer or any income you've received since 6th April
                        this year.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>

            {taxInfo?.hasHadPreviousJobInFiscalYear && (
              <>
                <div className="space-y-2">
                  <Label htmlFor="previousEmployerIncomeYTD">
                    Previous Employee Income YTD
                  </Label>
                  <Input
                    id="previousEmployerIncomeYTD"
                    name="previousEmployerIncomeYTD"
                    type="number"
                    value={taxInfo.previousEmployerIncomeYTD}
                    onChange={handleTaxInfoChange}
                    required
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="previousEmployerIncomeTaxYTD">
                    Previous Employee Income Tax YTD
                  </Label>
                  <Input
                    id="previousEmployerIncomeTaxYTD"
                    name="previousEmployerIncomeTaxYTD"
                    type="number"
                    value={taxInfo.previousEmployerIncomeTaxYTD}
                    onChange={handleTaxInfoChange}
                    required
                  />
                </div>
              </>
            )}

            <div className="space-y-2">
              <Label
                htmlFor="starterDeclaration"
                className="flex items-center space-x-2"
              >
                <span>Starter Declaration</span>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <HelpCircle className="h-4 w-4 text-gray-500" />
                    </TooltipTrigger>
                    <TooltipContent className="max-w-md">
                      <p className="mb-2">
                        You need a starter declaration if you don't have a P45
                        or have left your last job before April 6th of the
                        current fiscal year.
                      </p>
                      <p className="mb-2">
                        <strong>Statement A:</strong>{" "}
                        {starterDeclarationTooltips.A}
                      </p>
                      <p className="mb-2">
                        <strong>Statement B:</strong>{" "}
                        {starterDeclarationTooltips.B}
                      </p>
                      <p>
                        <strong>Statement C:</strong>{" "}
                        {starterDeclarationTooltips.C}
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </Label>
              <Select
                name="starterDeclaration"
                value={taxInfo?.starterDeclaration || "None"}
                onValueChange={(value) =>
                  handleTaxInfoChange({
                    target: { name: "starterDeclaration", value },
                  })
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select starter declaration" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="A">Statement A</SelectItem>
                  <SelectItem value="B">Statement B</SelectItem>
                  <SelectItem value="C">Statement C</SelectItem>
                  <SelectItem value="NONE">None</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label
                htmlFor="taxRegime"
                className="flex items-center space-x-2"
              >
                <span>Tax Regime</span>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <HelpCircle className="h-4 w-4 text-gray-500" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        If you reside in Scotland or Wales, HMRC will have
                        denoted this in your tax code with a prefix of 'S' for
                        Scottish or 'C' for Welsh.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </Label>
              <Select
                name="taxRegime"
                value={taxInfo?.taxRegime || "N/A"}
                onValueChange={(value) =>
                  handleTaxInfoChange({
                    target: { name: "taxRegime", value },
                  })
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select tax regime" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="S">Scotland</SelectItem>
                  <SelectItem value="C">Welsh</SelectItem>
                  <SelectItem value="N/A">None</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label htmlFor="taxCode" className="flex items-center space-x-2">
                <span>Tax Code</span>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <HelpCircle className="h-4 w-4 text-gray-500" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        Enter your full tax code except for the prefixed 'S' or
                        'C'. This will come directly from your P45. If you don't
                        know your tax code, use the default 1257L tax code.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </Label>
              <div className="flex items-center space-x-4">
                <Input
                  id="taxCode"
                  name="taxCode"
                  value={taxInfo?.taxCode || "1257L"}
                  onChange={handleTaxInfoChange}
                  required
                  className="w-1/2"
                />
                <div className="flex items-center space-x-2 w-1/2">
                  <Switch
                    id="isCumulative"
                    name="isCumulative"
                    checked={!taxInfo?.isCumulative || false}
                    onCheckedChange={(checked: boolean) =>
                      handleTaxInfoChange({
                        target: {
                          name: "isCumulative",
                          value: checked,
                        },
                      })
                    }
                  />
                  <Label htmlFor="isCumulative" className="whitespace-nowrap">
                    Week 1 / Month 1
                  </Label>
                </div>
              </div>
              {errors.taxCode && (
                <p className="text-red-500 text-sm">{errors.taxCode}</p>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="payId" className="flex items-center space-x-2">
                <span>Pay Id</span>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <HelpCircle className="h-4 w-4 text-gray-500" />
                    </TooltipTrigger>
                    <TooltipContent className="max-w-md">
                      <p>
                        The PayID is a crucial identifier used to communicate
                        employment details to HMRC:
                      </p>
                      <ul className="list-disc pl-5 mt-2">
                        <li>It's typically a number between 1-999</li>
                        <li>
                          Once a filing has been made with HMRC using this
                          PayID, it cannot be changed for the tax year
                        </li>
                        <li>
                          If switching payroll providers, it's essential to use
                          the same PayID as previously used
                        </li>
                        <li>
                          Using a different PayID could lead to duplicate
                          records or tax calculation errors with HMRC
                        </li>
                      </ul>
                      <p className="mt-2">
                        If you're unsure about the correct PayID, please check
                        previous payslips or consult with your previous payroll
                        provider or employer.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </Label>
              <Input
                id="payId"
                name="payId"
                value={taxInfo.payId || "1"}
                onChange={handleTaxInfoChange}
                required
              />
            </div>
          </div>
          <div className="col-span-2 space-y-2">
            <div className="flex items-center space-x-2">
              <Switch
                id="hasStudentLoan"
                name="hasStudentLoan"
                checked={taxInfo?.hasStudentLoan || false}
                onCheckedChange={(checked: boolean) =>
                  handleTaxInfoChange({
                    target: {
                      name: "hasStudentLoan",
                      value: checked,
                    },
                  })
                }
              />
              <Label htmlFor="hasStudentLoan">Has Student Loan</Label>
            </div>
          </div>
          {taxInfo?.hasStudentLoan && (
            <div className="col-span-2 space-y-2">
              <Label htmlFor="studentLoanPlan">Student Loan Plan</Label>
              <Select
                name="studentLoanPlan"
                value={taxInfo.studentLoanPlan || ""}
                onValueChange={(value) =>
                  handleTaxInfoChange({
                    target: { name: "studentLoanPlan", value },
                  })
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select plan" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Plan1">Plan 1</SelectItem>
                  <SelectItem value="Plan2">Plan 2</SelectItem>
                  <SelectItem value="Plan4">Plan 4</SelectItem>
                </SelectContent>
              </Select>
            </div>
          )}
          <div className="col-span-2 space-y-2">
            <div className="flex items-center space-x-2">
              <Switch
                id="hasPostgradLoan"
                name="hasPostgradLoan"
                checked={taxInfo?.hasPostgradLoan || false}
                onCheckedChange={(checked: boolean) =>
                  handleTaxInfoChange({
                    target: {
                      name: "hasPostgradLoan",
                      value: checked,
                    },
                  })
                }
              />
              <Label htmlFor="hasPostgradLoan">Has Postgrad Loan</Label>
            </div>
          </div>
          <div className="col-span-2">
            <Button
              type="submit"
              className="w-full"
              disabled={Object.values(errors).some((error) => error !== "")}
            >
              Update Tax Information
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default TaxInformation;
