import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import ErrorAlertDialog from "@/components/ErrorAlertDialog";

interface LeaveBalance {
  policyId: number;
  name: string;
  policyDisplayName: string;
  ukEmployerEmployeeId: number;
  leaveTypeVersionId: number;
  balance: number;
}

interface LogTimeOffModalProps {
  isOpen: boolean;
  onClose: () => void;
  leaveBalances: LeaveBalance[];
}

const LogTimeOffModal: React.FC<LogTimeOffModalProps> = ({
  isOpen,
  onClose,
  leaveBalances,
}) => {
  const [selectedEmployee, setSelectedEmployee] = useState<string>("");
  const [selectedLeavePolicy, setSelectedLeavePolicy] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [reason, setReason] = useState<string>("");
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    title: "",
    description: "",
  });

  useEffect(() => {
    // Reset form state when modal is opened
    if (isOpen) {
      setSelectedEmployee("");
      setSelectedLeavePolicy("");
      setStartDate(undefined);
      setEndDate(undefined);
      setReason("");
    }
  }, [isOpen]);

  const handleSubmit = async () => {
    try {
      // Basic input validation
      if (!selectedEmployee || !selectedLeavePolicy || !startDate || !endDate) {
        setErrorMessage({
          title: "Incomplete Form",
          description: "Please fill in all required fields.",
        });
        setIsErrorDialogOpen(true);
        return;
      }

      // Ensure end date is not before start date
      if (endDate < startDate) {
        setErrorMessage({
          title: "Invalid Date Range",
          description: "End date cannot be earlier than start date.",
        });
        setIsErrorDialogOpen(true);
        return;
      }

      const utcStartDate = new Date(
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
        ),
      );
      const utcEndDate = new Date(
        Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()),
      );

      const response = await fetch(
        "http://localhost:8000/employees/pto/leaves/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          body: JSON.stringify({
            ukEmployerEmployeeId: selectedEmployee,
            leaveTypeVersionId: selectedLeavePolicy,
            startDate: utcStartDate.toISOString(),
            endDate: utcEndDate.toISOString(),
            reason,
          }),
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to log time off");
      }

      onClose();
      // You might want to add some success feedback here
    } catch (error) {
      console.error("Error logging time off:", error);
      setErrorMessage({
        title: "Error",
        description:
          error instanceof Error
            ? error.message
            : "An unexpected error occurred. Please try again.",
      });
      setIsErrorDialogOpen(true);
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Log Time Off</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="employee" className="text-right">
                Employee
              </Label>
              <Select
                onValueChange={setSelectedEmployee}
                value={selectedEmployee}
              >
                <SelectTrigger className="col-span-3">
                  <SelectValue placeholder="Select employee" />
                </SelectTrigger>
                <SelectContent>
                  {leaveBalances.map((leaveBalance) => (
                    <SelectItem
                      key={leaveBalance.ukEmployerEmployeeId}
                      value={leaveBalance.ukEmployerEmployeeId.toString()}
                    >
                      {leaveBalance.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="leavePolicy" className="text-right">
                Leave Policy
              </Label>
              <Select
                onValueChange={setSelectedLeavePolicy}
                value={selectedLeavePolicy}
              >
                <SelectTrigger className="col-span-3">
                  <SelectValue placeholder="Select leave policy" />
                </SelectTrigger>
                <SelectContent>
                  {leaveBalances.map((policy) => (
                    <SelectItem
                      key={policy.leaveTypeVersionId}
                      value={policy.leaveTypeVersionId.toString()}
                    >
                      {policy.policyDisplayName}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="startDate" className="text-right">
                Start Date
              </Label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    className={`col-span-3 justify-start text-left font-normal ${
                      !startDate && "text-muted-foreground"
                    }`}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {startDate ? (
                      format(startDate, "PPP")
                    ) : (
                      <span>Pick a date</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={startDate}
                    onSelect={setStartDate}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="endDate" className="text-right">
                End Date
              </Label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    className={`col-span-3 justify-start text-left font-normal ${
                      !endDate && "text-muted-foreground"
                    }`}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {endDate ? (
                      format(endDate, "PPP")
                    ) : (
                      <span>Pick a date</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={endDate}
                    onSelect={setEndDate}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="reason" className="text-right">
                Reason
              </Label>
              <Input
                id="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                className="col-span-3"
              />
            </div>
          </div>
          <DialogFooter>
            <Button type="submit" onClick={handleSubmit}>
              Log Time Off
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <ErrorAlertDialog
        isOpen={isErrorDialogOpen}
        onClose={() => setIsErrorDialogOpen(false)}
        title={errorMessage.title}
        description={errorMessage.description}
      />
    </>
  );
};

export default LogTimeOffModal;
