import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import MenuBar from "@/components/MenuBar";
import { PlusCircle, Trash2 } from "lucide-react";
import AddCustomRateModal from "@/components/AddCustomRateModal";
import EmployeeDeductions from "@/components/earnings/interfaces";
import ApiService from "@/services/api";
import { EndpointKeys } from "@/config/api";

interface CustomRate {
  id: number;
  name: string;
  rateType: string;
  unitType: string;
  multiplier?: number;
  fixedAmount?: number;
}

interface Employee {
  id: number;
  firstName: string;
  lastName: string;
}

interface EmployeeEarning {
  employee: Employee;
  customRates: CustomRate[];
}

interface EmployeeEarningsResponse {
  employees: EmployeeEarning[];
}

interface RateTypesResponse {
  rateTypes: string[];
}

const Earnings: React.FC = () => {
  const [availableRateTypes, setAvailableRateTypes] = useState<string[]>([]);
  const [employeeEarnings, setEmployeeEarnings] = useState<EmployeeEarning[]>(
    [],
  );
  const [employeeDeductions, setEmployeeDeductions] =
    useState<EmployeeDeductions | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEmployeeId, setCurrentEmployeeId] = useState<number | null>(
    null,
  );

  useEffect(() => {
    fetchEmployees();
    fetchAvailableRateTypes();
    fetchEmployeeDeductions();
  }, []);

  const fetchEmployeeDeductions = async () => {
    // This endpoint is used to fetch the deductions for any dependent deductions when creating custom rates
    try {
      const response = await ApiService.request<EmployeeDeductions>(
        EndpointKeys.FETCH_EMPLOYEE_DEDUCTIONS,
        {},
      );

      setEmployeeDeductions(response);
    } catch (error) {
      console.error("Error fetching employee deductions:", error);
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await ApiService.request<EmployeeEarningsResponse>(
        EndpointKeys.FETCH_EMPLOYEE_EARNINGS,
        {},
      );

      setEmployeeEarnings(response.employees);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  const fetchAvailableRateTypes = async () => {
    try {
      const response = await ApiService.request<RateTypesResponse>(
        EndpointKeys.FETCH_AVAILABLE_RATE_TYPES,
        {},
      );

      setAvailableRateTypes(response.rateTypes);
    } catch (error) {
      console.error("Error fetching available rate types:", error);
    }
  };

  const handleAddCustomRate = (employeeId: number) => {
    setCurrentEmployeeId(employeeId);
    setIsModalOpen(true);
  };

  const handleRemoveCustomRate = async (employeeId: number, rateId: number) => {
    try {
      await ApiService.request<any>(EndpointKeys.REMOVE_CUSTOM_RATE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          employeeId,
          rateId,
        }),
      });

      setEmployeeEarnings(
        employeeEarnings.map((emp) => {
          if (emp.employee.id === employeeId) {
            return {
              ...emp,
              customRates: emp.customRates.filter((rate) => rate.id !== rateId),
            };
          }
          return emp;
        }),
      );
    } catch (error) {
      console.error("Error removing custom rate:", error);
    }
  };

  const handleAddRate = async (rate: Omit<CustomRate, "id">) => {
    if (currentEmployeeId === null) return;

    try {
      const newRate = await ApiService.request<CustomRate>(
        EndpointKeys.ADD_CUSTOM_RATE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            employeeId: currentEmployeeId,
            ...rate,
          }),
        },
      );

      setEmployeeEarnings(
        employeeEarnings.map((emp) => {
          if (emp.employee.id === currentEmployeeId) {
            return {
              ...emp,
              customRates: [...emp.customRates, newRate],
            };
          }
          return emp;
        }),
      );
    } catch (error) {
      console.error("Error adding custom rate:", error);
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <MenuBar />
      <div className="flex-1 overflow-auto">
        <Card className="m-6">
          <CardHeader>
            <h2 className="text-2xl font-bold">
              Custom Earnings Configuration
            </h2>
          </CardHeader>
          <CardContent>
            {employeeEarnings.map((employee) => (
              <Card key={employee.employee.id} className="mb-6">
                <CardHeader>
                  <h3 className="text-xl font-semibold">{`${employee.employee.firstName} ${employee.employee.lastName}`}</h3>
                </CardHeader>
                <CardContent>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Rate Name</TableHead>
                        <TableHead>Rate Type</TableHead>
                        <TableHead>Unit Type</TableHead>
                        <TableHead>Multiplier</TableHead>
                        <TableHead>Fixed Amount</TableHead>
                        <TableHead>Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {employee.customRates.map((rate) => (
                        <TableRow key={rate.id}>
                          <TableCell>{rate.name}</TableCell>
                          <TableCell>{rate.rateType}</TableCell>
                          <TableCell>{rate.unitType}</TableCell>
                          <TableCell>{rate.multiplier || "N/A"}</TableCell>
                          <TableCell>{rate.fixedAmount || "N/A"}</TableCell>
                          <TableCell>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() =>
                                handleRemoveCustomRate(
                                  employee.employee.id,
                                  rate.id,
                                )
                              }
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Button
                    className="mt-4"
                    onClick={() => handleAddCustomRate(employee.employee.id)}
                  >
                    <PlusCircle className="mr-2 h-4 w-4" />
                    Add Custom Rate
                  </Button>
                </CardContent>
              </Card>
            ))}
          </CardContent>
        </Card>
        <AddCustomRateModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onAdd={handleAddRate}
          availableRateTypes={availableRateTypes}
          availableDependentDeductions={employeeDeductions}
        />
      </div>
    </div>
  );
};

export default Earnings;
