import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Alert, AlertDescription } from "@/components/ui/alert";
import ApiService from "@/services/api";
import { EndpointKeys } from "@/config/api";

interface LeavePolicy {
  id: number;
  policyDisplayName: string;
  accrualPeriod: string;
  canHaveNegativeBalance: boolean;
  accrualStartFrom: string | null;
  requiresProof: boolean;
  numberOfHoursPerPeriod: number;
}

interface PtoPolicyResponse {
  leavePolicies: LeavePolicy[];
}

const PolicyConfig: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [policy, setPolicy] = useState<LeavePolicy | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchPolicy();
  }, [id]);

  const fetchPolicy = async () => {
    if (!id) {
      return;
    }
    try {
      const response = await ApiService.request<PtoPolicyResponse>(
        EndpointKeys.PTO_POLICY,
        {
          urlParams: { id },
        },
      );

      setPolicy(response.leavePolicies[0]);
    } catch (error) {
      console.error("Error fetching policy:", error);
      setError("Failed to load policy. Please try again.");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    setPolicy((prev) =>
      prev
        ? { ...prev, [name]: type === "checkbox" ? e.target.checked : value }
        : null,
    );
  };

  const handleSelectChange = (name: string, value: string) => {
    setPolicy((prev) => (prev ? { ...prev, [name]: value } : null));
  };

  const handleSwitchChange = (name: string, checked: boolean) => {
    setPolicy((prev) => (prev ? { ...prev, [name]: checked } : null));
  };

  const handleUpdate = async () => {
    try {
      await ApiService.request<any>(EndpointKeys.UPDATE_PTO_POLICY, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(policy),
      });

      navigate("/time-off");
    } catch (error) {
      console.error("Error updating policy:", error);
      setError("Failed to update policy. Please try again.");
    }
  };

  if (!policy) return <div>Loading...</div>;

  return (
    <div className="container mx-auto p-4">
      <Card>
        <CardHeader>
          <h2 className="text-2xl font-bold">Configure Leave Policy</h2>
        </CardHeader>
        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          <form className="space-y-4">
            <div>
              <Label htmlFor="policyDisplayName">Policy Name</Label>
              <Input
                id="policyDisplayName"
                name="policyDisplayName"
                value={policy.policyDisplayName}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <Label htmlFor="numberOfHoursPerPeriod">
                Number of Hours Per Period
              </Label>
              <Input
                id="numberOfHoursPerPeriod"
                name="numberOfHoursPerPeriod"
                value={policy.numberOfHoursPerPeriod}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <Label htmlFor="accrualPeriod">Accrual Period</Label>
              <Select
                name="accrualPeriod"
                value={policy.accrualPeriod}
                onValueChange={(value) =>
                  handleSelectChange("accrualPeriod", value)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select accrual period" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Daily">Daily</SelectItem>
                  <SelectItem value="Monthly">Monthly</SelectItem>
                  <SelectItem value="Yearly">Yearly</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center space-x-2">
              <Switch
                id="canHaveNegativeBalance"
                checked={policy.canHaveNegativeBalance}
                onCheckedChange={(checked) =>
                  handleSwitchChange("canHaveNegativeBalance", checked)
                }
              />
              <Label htmlFor="canHaveNegativeBalance">
                Allow Negative Balance
              </Label>
            </div>
            <div>
              <Label htmlFor="accrualStartFrom">Accrual Start</Label>
              <Select
                name="accrualStartFrom"
                value={policy.accrualStartFrom || ""}
                onValueChange={(value) =>
                  handleSelectChange("accrualStartFrom", value)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select accrual start" />
                </SelectTrigger>
                <SelectContent>
                  {/*<SelectItem value="">N/A</SelectItem>*/}
                  <SelectItem value="Fiscal">Fiscal Year</SelectItem>
                  <SelectItem value="Calendar">Calendar Year</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center space-x-2">
              <Switch
                id="requiresProof"
                checked={policy.requiresProof}
                onCheckedChange={(checked) =>
                  handleSwitchChange("requiresProof", checked)
                }
              />
              <Label htmlFor="requiresProof">Requires Proof</Label>
            </div>
          </form>
        </CardContent>
        <CardFooter className="flex justify-end space-x-4">
          <Button onClick={handleUpdate}>Submit</Button>
          <Button variant="outline" onClick={() => navigate("/time-off")}>
            Cancel
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default PolicyConfig;
