import React from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuBar from "./MenuBar";
import PayrunOverview from "./PayrunOverview";

const DarkCard = styled(Card)(({ theme }) => ({
  backgroundColor: "gray",
  color: "white",
  borderRadius: theme.spacing(2),
}));

const BlueButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#3a8ff7",
  color: "white",
  "&:hover": {
    backgroundColor: "#2d7de0",
  },
}));

const Dashboard: React.FC = () => {
  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <MenuBar />
      <Box sx={{ flexGrow: 1, p: 4 }}>
        <Typography variant="h4" sx={{ mb: 4 }}>
          Payroll Dashboard
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 4,
            mb: 6,
          }}
        >
          <DashboardCard
            title="Upcoming Payrun"
            value="June 30, 2023"
            subtitle="Next payroll date"
          />
          <DashboardCard
            title="Employees"
            value="124"
            subtitle="Total active employees"
          />
          <DashboardCard
            title="Pending Time Off Requests"
            value="7"
            subtitle="Requests needing approval"
          />
        </Box>
        <Typography variant="h5" sx={{ mb: 3 }}>
          Recent Payruns
        </Typography>
        <PayrunOverview />
      </Box>
    </Box>
  );
};

const DashboardCard: React.FC<{
  title: string;
  value: string;
  subtitle: string;
}> = ({ title, value, subtitle }) => (
  <DarkCard>
    <CardContent>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Typography variant="h4" sx={{ mb: 1, color: "black" }}>
        {value}
      </Typography>
      <Typography variant="body2" sx={{ color: "rgba(255,255,255,0.7)" }}>
        {subtitle}
      </Typography>
    </CardContent>
  </DarkCard>
);

export default Dashboard;
